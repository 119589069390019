import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box, Typography } from "@material-ui/core";
import STRINGS from "../../../strings/home";
const Container = styled(Box)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled(Typography)`
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const NoDraftsContent = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Container $mobile={mobile}>
      <Title variant="h5" component="h5">
        {STRINGS.welcome.title}
      </Title>
      <Typography variant="body1" component="p">
        {STRINGS.welcome.subtitle}
      </Typography>
    </Container>
  );
};

export default NoDraftsContent;
