import React, { useState } from "react";
import {
  Dialog,
  Card,
  CardMedia,
  Avatar,
  Select,
  MenuItem,
  CardActions,
  IconButton,
  Menu,
} from "@material-ui/core";
import styled from "styled-components";

import { PERMISSIONS } from "../../../services/PermissionService";
import { useDraftPermissionsContext } from "../../../context";
import { Auth } from "../../../App";

import {
  Close,
  Check,
  MoreVert,
  PermIdentity,
  AssignmentIndOutlined,
  VisibilityOutlined,
  PhotoLibraryOutlined,
} from "@material-ui/icons";

import {
  useUser,
  getNameInitials,
  useUserPermissions,
} from "../../../services/UserService";

const MyCard = styled(Card)`
  height: 430px;
  display: flex;
  flex-direction: column;
  width: 220px;
`;

const MyCardMedia = styled(CardMedia)`
  flex: 1;
  width: 100%;
`;

const AvatarContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  height: 1px;
`;

const MyAvatar = styled(Avatar)`
  height: 100%;
  width: 100%;
  font-size: 80px;
  font-weight: 900;
`;

const Content = styled.div`
  width: 100%;
  padding: 1rem;
  padding-bottom: 0;
`;

const FirstName = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const MyCardActions = styled(CardActions)`
  width: 100%;
  padding-top: 0;
  padding: 0.5rem;
`;

const RightActions = styled.div`
  margin-left: auto;
`;

const Permission = styled.div`
  font-size: 12px;
  color: #747474;
`;

const TeamMemberDialog = ({
  open,
  onClose,
  mobile,
  userId,
  draftId,
  onConfirm,
  onRemoveUser,
}) => {
  const user = useUser({ userId });
  const userPermission = useUserPermissions({ userId, draftId });
  const permissionKey = userPermission && userPermission.key;
  const userNameLetters = user && getNameInitials({ name: user.name });
  const [newPermissionKey, setNewPermissionKey] = useState();
  const [anchorOverflow, setAnchorOverflow] = useState(null);
  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;
  const currentUserId = Auth.currentUser.uid;

  const firstName = user?.name?.length > 0 && user.name.split(" ")[0];
  const disabledOk =
    newPermissionKey === undefined || newPermissionKey === permissionKey;
  const UserIcon = () => {
    const permission = newPermissionKey ?? permissionKey;
    if (permission === "owner") {
      return <PermIdentity />;
    }
    if (permission === "editor") {
      return <AssignmentIndOutlined />;
    }
    if (permission === "viewer") {
      return <VisibilityOutlined />;
    }
    return <PhotoLibraryOutlined />;
  };

  const onPermissionChange = (event) => {
    setNewPermissionKey(event.target.value);
  };

  const onClickConfirm = () => {
    onConfirm(userId, newPermissionKey);
  };

  const onClickRemoveUser = () => {
    setAnchorOverflow(null);
    onRemoveUser(userId);
  };

  const isCurrentUser = currentUserId === user.usersId;
  const showPermmissionSelect =
    canEdit && permissionKey !== "owner" && !isCurrentUser;
  const canRemoveUser = canEdit && permissionKey !== "owner" && !isCurrentUser;

  return (
    <Dialog
      fullScreen={mobile}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <MyCard>
        {user.img ? (
          <MyCardMedia image={user.img} />
        ) : (
          <AvatarContainer>
            <MyAvatar variant="square">{userNameLetters}</MyAvatar>
          </AvatarContainer>
        )}
        <Content>
          <UserIcon />
          {firstName && <FirstName>{firstName}</FirstName>}
          {!showPermmissionSelect && (
            <Permission>{PERMISSIONS[permissionKey]?.text}</Permission>
          )}
          {showPermmissionSelect && permissionKey && (
            <Select
              value={newPermissionKey ?? permissionKey}
              onChange={onPermissionChange}
            >
              {Object.entries(PERMISSIONS)
                .filter(([key, value]) => key !== "owner")
                .map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value.key}>
                      {value.text}
                    </MenuItem>
                  );
                })}
            </Select>
          )}
        </Content>
        <MyCardActions disableSpacing={true}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={(e) => setAnchorOverflow(e.currentTarget)}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="menuOverflow"
            anchorEl={anchorOverflow}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={anchorOverflow !== null}
            onClose={() => setAnchorOverflow(null)}
          >
            <MenuItem onClick={onClickRemoveUser} disabled={!canRemoveUser}>
              Remove User
            </MenuItem>
          </Menu>
          <RightActions>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
            <IconButton onClick={onClickConfirm} disabled={disabledOk}>
              <Check />
            </IconButton>
          </RightActions>
        </MyCardActions>
      </MyCard>
    </Dialog>
  );
};

export default TeamMemberDialog;
