import React, { useState } from "react";
import {
  Box,
  Fab,
  Tabs,
  Tab,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import styled from "styled-components";
import { Add } from "@material-ui/icons/";
import { useHistory, Link } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SideBar = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin: 1rem;
  flex-direction: column;
`;

const SideBarMobile = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 6rem;
  right: 1rem;
  z-index: 1;
`;

const MySpeedDial = styled(SpeedDial)`
  margin-left: 0.5rem;
`;

const MyFab = styled(Fab)`
  margin-left: 0.5rem;
`;

const MyTabs = styled(Tabs)`
  margin-top: 1rem;
`;

const MyTab = styled(Tab)`
  padding: 0;
  min-width: 72px;
`;

const HiddenTab = styled(Tab)`
  width: 0;
  height: 0;
  display: none;
  overflow: hidden;
`;

const HiddenBottomNavigationAction = styled(BottomNavigationAction)`
  width: 0;
  height: 0;
  display: none;
  overflow: hidden;
`;

const MyBottomNavigation = styled(BottomNavigation)`
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 80px;
  z-index: 1;

  & .MuiBottomNavigationAction-label {
    font-size: 16px;
    margin-top: 0.75rem;
  }
`;

const SideBarContent = ({ config }) => {
  const [showSpeedDialActions, setShowSpeedDialActions] = useState(false);
  const history = useHistory();
  const tabs = config.tabs;

  const currentTab =
    tabs && tabs.find((tab) => tab.route === history.location.pathname);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const speedDial = config.speedDial;
  const fab = config.fab;
  const actions = speedDial?.actions;
  const handleOpen = () => {
    setShowSpeedDialActions(true);
  };
  const handleClose = () => {
    setShowSpeedDialActions(false);
  };

  const handleChange = (_, newValue) => {
    history.push(newValue);
  };

  const handleClick = (action) => {
    handleClose();
    action.onClick();
  };

  return mobile ? (
    <>
      <SideBarMobile>
        {speedDial && (
          <SpeedDial
            ariaLabel="SpeedDial"
            icon={<Add />}
            onClose={handleClose}
            onClick={handleOpen}
            open={showSpeedDialActions}
            direction="up"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.mobileName}
                icon={action.icon}
                tooltipTitle={action.mobileName}
                onClick={() => handleClick(action)}
                tooltipOpen
              />
            ))}
          </SpeedDial>
        )}
        {fab && (
          <MyFab onClick={fab.onClick} color={fab.color} aria-label="fab">
            {fab.icon}
          </MyFab>
        )}
      </SideBarMobile>
      {tabs && (
        <MyBottomNavigation
          value={currentTab?.route || "hidden"}
          onChange={handleChange}
        >
          <HiddenBottomNavigationAction label="hidden" value="hidden" />
          {tabs.map((tab) => {
            return (
              <BottomNavigationAction
                key={tab.label}
                label={tab.route === currentTab?.route ? tab.label : ""}
                value={tab.route}
                icon={tab.icon}
              />
            );
          })}
        </MyBottomNavigation>
      )}
    </>
  ) : (
    <SideBar>
      {speedDial && (
        <MySpeedDial
          ariaLabel="SpeedDial"
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onClick={handleOpen}
          open={showSpeedDialActions}
          direction="right"
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClick(action)}
            />
          ))}
        </MySpeedDial>
      )}
      {fab && (
        <MyFab onClick={fab.onClick} color={fab.color} aria-label="fab">
          {fab.icon}
        </MyFab>
      )}
      {tabs && (
        <MyTabs
          orientation="vertical"
          name="tab"
          value={currentTab?.route || "hidden"}
          variant={mobile ? "fullWidth" : "standard"}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <HiddenTab label="hidden" value="hidden" />
          {tabs.map((tab) => (
            <MyTab
              key={tab.label}
              icon={tab.icon}
              label={tab.route === currentTab?.route ? tab.label : ""}
              value={tab.route}
              component={Link}
              to={tab.route}
            />
          ))}
        </MyTabs>
      )}
    </SideBar>
  );
};

export default SideBarContent;
