import React, { useEffect } from "react";
import { redirectToAccounts } from "../../services/AuthService";
const Login = () => {
  useEffect(() => {
    // redirect to accounts.myplayer.io for login
    redirectToAccounts();
  }, []);
  return <></>;
};

export default Login;
