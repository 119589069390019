import React from "react";
import { Checkbox, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import STRINGS from "../../../strings/general";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
  },
}));

const UpgradeDraftContent = ({
  versions,
  upgradeToVersion,
  createBackup,
  onChangeUpgradeToVersion,
  onChangeCreateBackup,
  disabled,
}) => {
  const classes = useStyles();

  const onChangeVersion = (event) => {
    onChangeUpgradeToVersion(event.target.value);
  };
  const onChangeBackup = () => {
    onChangeCreateBackup(!createBackup);
  };

  if (!versions || versions.length === 0) return <></>;

  return (
    <>
      <div className={classes.row}>
        <span className={classes.grow}>{STRINGS.draft.upgradeCaption}</span>
        <Select
          native
          onChange={onChangeVersion}
          value={upgradeToVersion}
          disabled={disabled}
        >
          <option key="opt-none" value=""></option>
          {versions.map((v) => (
            <option key={`opt-${v}`} value={v}>
              {v}
            </option>
          ))}
        </Select>
      </div>
      <div className={classes.row}>
        <span className={classes.grow}>{STRINGS.draft.upgradeBackup}</span>
        <Checkbox
          checked={createBackup}
          onChange={onChangeBackup}
          color="primary"
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default UpgradeDraftContent;
