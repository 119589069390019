import React, { useState } from "react";
import styled from "styled-components";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box,
  InputBase,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import {
  Sort,
  Search,
  ArrowDownward,
  ArrowUpward,
  MoreVert,
} from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import STRINGS from "../../../strings/home";
import { signOut, sessionLogout } from "../../../services/AuthService";

import { ACCOUNTS_URL, OWNED_BY_ITEMS } from "../../../config/config";
import { useAdminModeContext } from "../../../context";

export const LOGOUT_URL = `${ACCOUNTS_URL}/login`;

const RightContainer = styled(Box)`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MyInputBase = styled(InputBase)`
  width: ${(props) => (props.$showSearch ? "100%" : "0%")};
  transition: width 0.2s ease-in;
`;

const MenuItemText = styled.span`
  margin-right: 0.5rem;
`;

const MySelect = styled(Select)`
  &::before {
    content: none;
  }
  &::after {
    content: none;
  }
`;

const SORT_MENU_ITEMS = [
  {
    name: STRINGS.created,
    key: "createdAt",
  },
  {
    name: STRINGS.name,
    key: "name",
  },
  {
    name: STRINGS.brand,
    key: "clientName",
  },
];

export default function Navbar({
  searchText,
  setSearchText,
  sortBy,
  setSortBy,
  sortDesc,
  setSortDesc,
  ownedBy,
  setOwnedBy,
}) {
  const [showSearch, setShowSearch] = useState(false);
  const [showSortMenu, setShowSortMenu] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setAdminMode = useAdminModeContext()[1];

  const onBlur = () => {
    if (searchText.length === 0) {
      setShowSearch(false);
    }
  };
  const onChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const onClick = () => {
    setShowSearch(true);
  };

  const handleClickItem = (key) => {
    if (key === sortBy) {
      setSortDesc(!sortDesc);
    }
    setSortBy(key);
    handleSortClose();
  };

  const handleSortClose = (key) => {
    setShowSortMenu(false);
  };

  const handleMoreClose = () => {
    setShowMoreMenu(false);
  };

  const handleLogout = async () => {
    await sessionLogout();
    await signOut();
    return (window.location.href = LOGOUT_URL);
  };

  const handleOwnedByChange = (e) => {
    setOwnedBy(e.target.value);
  };
  const onHeaderClick = (e) => {
    if (e.detail === 4) {
      setAdminMode(true);
    }
  };

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger} >
      <AppBar color="inherit" position="sticky">
        <Toolbar>
          <Typography onClick={onHeaderClick} variant="h6">
            {STRINGS.header}
          </Typography>
          <RightContainer>
            {!mobile && (
              <FormControl>
                <MySelect
                  id="owned-by-select"
                  value={ownedBy}
                  onChange={handleOwnedByChange}
                >
                  {Object.keys(OWNED_BY_ITEMS).map((key) => {
                    const value = OWNED_BY_ITEMS[key];
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </MySelect>
              </FormControl>
            )}
            {showSearch ? (
              <MyInputBase
                autoFocus
                $showSearch={showSearch || searchText.length > 0}
                placeholder={STRINGS.search.placeholder}
                onBlur={onBlur}
                onChange={onChange}
              />
            ) : (
              <IconButton
                onClick={onClick}
                color="inherit"
                aria-label="search"
              >
                <Search />
              </IconButton>
            )}
            <IconButton
              aria-haspopup="true"
              onClick={(e) => setShowSortMenu(e.currentTarget)}
              color="inherit"
            >
              <Sort />
            </IconButton>
            <Menu
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              anchorEl={showSortMenu}
              keepMounted
              open={Boolean(showSortMenu)}
              onClose={handleSortClose}
            >
              {SORT_MENU_ITEMS.map((item, index) => {
                const selected = sortBy === item.key;
                return (
                  <MenuItem
                    selected={selected}
                    key={index}
                    onClick={() => handleClickItem(item.key)}
                  >
                    <MenuItemText>{item.name}</MenuItemText>
                    {selected && sortDesc && (
                      <ArrowDownward fontSize="small" />
                    )}
                    {selected && !sortDesc && (
                      <ArrowUpward fontSize="small" />
                    )}
                  </MenuItem>
                );
              })}
            </Menu>
            <IconButton
              aria-haspopup="true"
              onClick={(e) => setShowMoreMenu(e.currentTarget)}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
            <Menu
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              anchorEl={showMoreMenu}
              keepMounted
              open={Boolean(showMoreMenu)}
              onClose={handleMoreClose}
            >
              <MenuItem onClick={handleLogout}>
                <MenuItemText>{STRINGS.logout}</MenuItemText>
              </MenuItem>
            </Menu>
          </RightContainer>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}
