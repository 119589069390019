import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { MyButton } from "../../../components";
import { REQUEST_TIMEOUT_SECONDS } from "../../../config/config";

const RequestReceivedDialog = ({
  open,
  draftSessionRequest,
  onReject,
  onAccept,
  timeLeft,
}) => {
  const name = draftSessionRequest?.name;

  const timeLeftText = timeLeft
    ? `You have ${timeLeft} seconds to respond.`
    : `You have ${REQUEST_TIMEOUT_SECONDS} seconds to respond.`;

  return (
    <Dialog open={open}>
      <DialogTitle>Edit Request Received</DialogTitle>
      <DialogContent>
        {name && (
          <DialogContentText>
            {name} has requested to edit this draft. {timeLeftText}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <MyButton
          text="Reject"
          onClick={onReject}
          variant="contained"
          color="default"
        />
        <MyButton
          text="Accept"
          onClick={onAccept}
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RequestReceivedDialog;
