import React, { useEffect } from "react";
import styled from "styled-components";
import {
  Dialog,
  Card,
  CardContent,
  CardMedia,
  Button,
  LinearProgress,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../routes/routes";
import { joinDraft } from "../../../services/ApiService";
import { Spinner } from "../../../components";

const MyCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MyCardMedia = styled(CardMedia)`
  min-height: 300px;
  width: 100%;
`;

const MyCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
`;

const DraftName = styled.span`
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0.5rem;
`;

const DraftBrand = styled.span`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1rem;
`;

const CardActions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const MyButton = styled(Button)`
  text-transform: none;
  width: 11rem;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const PreviewDraftDialog = ({
  open,
  handleClose,
  joinCode,
  draft,
  draftcode,
  progress,
  setProgress,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const loading = !draftcode || !draft;
  // handle invalid draftcode
  // handle if draftcode.draftId doesn't match a valid draft
  useEffect(() => {
    if (!joinCode) return;
    if (!open) return;
    if (
      draftcode === null ||
      draft === null ||
      (draftcode && draftcode.deleted)
    ) {
      enqueueSnackbar("Invalid draft code.", { variant: "error" });
      handleClose();
      return;
    }
  }, [open, joinCode, draftcode, draft, enqueueSnackbar, handleClose]);

  const onClose = () => {
    if (progress) return;
    handleClose();
  };

  const onClickJoin = async () => {
    if (progress) return;
    setProgress(true);
    try {
      const result = await joinDraft({
        joinCode,
      });
      if (result?.status === "fail") {
        enqueueSnackbar(result.message, {
          variant: "info",
        });
      } else {
        enqueueSnackbar("Draft joined.", { variant: "success" });
      }
      history.push(`${ROUTES.draft}/${draftcode?.draftId}`);
    } catch (e) {
      console.log(e.message);
      handleClose();
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth
    >
      <MyCard>
        {loading && <Spinner />}
        {progress && <LinearProgress />}
        {!loading && (
          <>
            {draft?.imageUrl && <MyCardMedia image={draft.imageUrl} />}
            <MyCardContent>
              <DraftName>{draft.name}</DraftName>
              {draft.clientName && <DraftBrand>{draft.clientName}</DraftBrand>}
            </MyCardContent>
            <CardActions>
              <MyButton
                variant="contained"
                onClick={onClose}
                disabled={progress}
              >
                Cancel
              </MyButton>
              <MyButton
                variant="contained"
                onClick={onClickJoin}
                color="primary"
                disabled={progress}
              >
                Join
              </MyButton>
            </CardActions>
          </>
        )}
      </MyCard>
    </Dialog>
  );
};

export default PreviewDraftDialog;
