import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import GENERAL_STRINGS from "../strings/general";

const MyDialog = styled(Dialog)`
  text-align: center;
`;

const MyDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: ${(props) => (props.$mobile ? "column" : "row")};
  justify-content: center;
  margin-bottom: 2rem;
`;

const MyButton = styled(Button)`
  width: 200;
`;

const ErrorDialog = ({
  open,
  description,
  title,
  onConfirm,
  mobile,
  onClose,
}) => {
  return (
    <MyDialog open={open} fullScreen={mobile} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <MyDialogActions disableSpacing={true} $mobile={mobile}>
        <MyButton
          color="primary"
          onClick={onConfirm}
          variant="contained"
          size="large"
        >
          <Typography variant="button">
            {GENERAL_STRINGS.button_okay}
          </Typography>
        </MyButton>
      </MyDialogActions>
    </MyDialog>
  );
};

export default ErrorDialog;
