import React from "react";
import styled from "styled-components";
import {Card, CardActionArea, CardContent, Typography} from "@material-ui/core";

const MyCardContainer = styled.div`
  display: flex;
  flex: 0 0 calc(34% - 0.46rem);
  width: 100%;
  min-width: 15rem;
`;

const MyCard = styled(Card)`
  height: 20rem;
  width: 100%;
  min-width: 15rem;
  margin: 1rem;
`;

const MyCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0.5rem;
`;

const Heading = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 1.25px;
`;

const Subtitle = styled(Typography)`
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
`;

const CardImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardImage = styled.img`
  width: 100%;
`;

const DraftTypeCard = ({draftType, createDraft, loading}) => {
  const {title, subtitle, key, image} = draftType;

  const onClick = () => {
    if (loading) return;
    createDraft({key});
  };
  return (
    <MyCardContainer>
      <MyCard>
        <MyCardActionArea disabled={loading} onClick={onClick}>
          <CardImageContainer>
            <CardImage src={image} alt=""></CardImage>
          </CardImageContainer>
          <CardContent>
            <Subtitle variant="body2">{subtitle}</Subtitle>
            <Heading variant="subtitle1" color="primary">
              {title}
            </Heading>
          </CardContent>
        </MyCardActionArea>
      </MyCard>
    </MyCardContainer>
  );
};

export default DraftTypeCard;
