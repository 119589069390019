import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import theme from "../config/theme";

const StyledButton = styled(Button)`
  color: ${theme.palette.white.main};
  letter-spacing: 1.25px;
`;

export default function MyButton({
  text,
  onClick,
  className,
  color = "primary",
}) {
  return (
    <StyledButton
      className={className}
      variant="contained"
      color={color}
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
}
