import React from "react";
import { Drawer, IconButton } from "@material-ui/core";
import styled from "styled-components";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import theme from "../../../config/theme";
import { CreateInviteCodesCard, InviteCodeListCard } from ".";

const StyledPaper = styled.div`
  height: calc(100% - 3rem);
  border-radius: 1rem 1rem 0px 0px;
`;

const FullHeightStyledPaper = styled.div`
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${theme.palette.grey.main};
  padding: 1rem;
`;

const CardsContainer = styled.div`
  height: ${(props) => !props.$mobile && "100%"};
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$mobile ? "column" : "row")};
`;

const TitleContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  padding-left: 1rem;
  align-items: center;
`;

const RightContainer = styled.div`
  margin-left: auto;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

const DrawerLayout = ({ open, mobile, onClose, children }) => {
  return (
    <Drawer
      style={{ height: "100%" }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{ component: mobile ? FullHeightStyledPaper : StyledPaper }}
    >
      {children}
    </Drawer>
  );
};

const TitleBar = ({ onClose }) => {
  return (
    <TitleContainer>
      <Title>Manage Invite Codes</Title>
      <RightContainer>
        <IconButton onClick={onClose}>
          <ExpandMoreOutlined />
        </IconButton>
      </RightContainer>
    </TitleContainer>
  );
};

const DrawerContent = ({ mobile }) => {
  return (
    <Content>
      <CardsContainer $mobile={mobile}>
        <InviteCodeListCard />
        <CreateInviteCodesCard />
      </CardsContainer>
    </Content>
  );
};

const InviteCodeDrawer = ({ open, onClose }) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <DrawerLayout mobile={mobile} open={open} onClose={onClose}>
      <Container>
        <TitleBar onClose={onClose} />
        <DrawerContent mobile={mobile} />
      </Container>
    </DrawerLayout>
  );
};

export default InviteCodeDrawer;
