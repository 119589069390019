import React from "react";
import styled from "styled-components";
import { Avatar, Tooltip } from "@material-ui/core";

import { useUser, getNameInitials } from "../services/UserService";

const DEFAULT_SIZE = "5rem";

const MyAvatar = styled(Avatar)`
  height: ${(props) => props.$size};
  width: ${(props) => props.$size};
`;

const UserAvatar = ({ userId, className, size = DEFAULT_SIZE }) => {
  const userInfo = useUser({ userId });
  const userNameLetters = userInfo && getNameInitials({ name: userInfo.name });
  if (!userInfo) return <></>;
  return (
    <Tooltip title={userInfo?.name ?? ""} placement="top">
      {userInfo.img ? (
        <MyAvatar
          className={className}
          $size={size}
          src={userInfo.img}
          alt={userInfo.name}
        />
      ) : (
        <MyAvatar className={className} $size={size}>
          {userNameLetters}
        </MyAvatar>
      )}
    </Tooltip>
  );
};

export default UserAvatar;
