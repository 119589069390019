import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { MyButton } from "../../../components";
import { REQUEST_TIMEOUT_SECONDS } from "../../../config/config";

const RequestSentDialog = ({ open, onCancel, timeLeft }) => {
  const timeLeftText = timeLeft
    ? `If there is no response, you will automatically
  take over in ${timeLeft} seconds.`
    : `If there is no response, you will automatically take over in ${REQUEST_TIMEOUT_SECONDS} seconds.`;
  return (
    <Dialog open={open}>
      <LinearProgress />
      <DialogTitle>Edit Request Sent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          A request has been sent to edit this draft. {timeLeftText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MyButton
          text="Cancel Request"
          onClick={onCancel}
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RequestSentDialog;
