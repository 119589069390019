import icon_create_mystories from "../images/icon_create_mystories.png";
import icon_create_signage from "../images/icon_create_signage.png";
import icon_create_myqr from "../images/icon_create_myqr.png";
import icon_create_myguide from "../images/icon_create_myguide.png";
export const DRAFT_TYPES = {
  mystories: {
    key: "mystories",
    title: "MyStories",
    subtitle: "Snappy interactive stories",
    image: icon_create_mystories,
    iframeId: "mystories-iframe",
    collection: "drafts_and_stories",
    storageFolder: "stories",
  },
  mystories_v2: {
    key: "mystories_v2",
    title: "MyStories v2.0 (Beta)",
    subtitle: "Snappy interactive stories",
    image: icon_create_mystories,
    iframeId: "mystories_v2-iframe",
    collection: "drafts_and_stories_v2",
    storageFolder: "storiesV2",
  },
  // tryme: {
  //   key: "tryme",
  //   title: "TryMe",
  // },
  mysignage: {
    key: "mysignage",
    title: "MySignage",
    subtitle: "Video and image playlists",
    image: icon_create_signage,
    iframeId: "mysignage-iframe",
    collection: "drafts_and_signage",
    storageFolder: "signage",
  },
  myqr: {
    key: "myqr",
    title: "MyQR",
    subtitle: "Redirect to any website",
    image: icon_create_myqr,
    iframeId: "myqr-iframe",
    collection: "drafts_and_qr",
    storageFolder: "qr",
  },
  myguide: {
    key: "myguide",
    title: "MyGuide (Beta)",
    subtitle: "Guided Experiences",
    image: icon_create_myguide,
    iframeId: "myguide-iframe",
    collection: "drafts_and_guide",
    storageFolder: "guide",
  },
};
