import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
} from "@material-ui/core";
import styled from "styled-components";
import { ROUTES, PROTOCOL, HOST } from "../../../routes/routes";
import { useDraftIdContext } from "../../../context";
import QRCode from "qrcode.react";
import { Close } from "@material-ui/icons";
import { openPreviewWindow } from "../utils";
import STRINGS from "../../../strings/general";
import { QR_CONFIG } from "../../../config/config";

const LeftActions = styled.div`
  margin-right: auto;
`;

const PreviewDialog = ({ open, onClose, mobile }) => {
  const draftId = useDraftIdContext();
  const src = `${PROTOCOL}//${HOST}${ROUTES.draft}/${draftId}${ROUTES.preview}`;
  const goToPreview = () => openPreviewWindow(draftId);

  return (
    <Dialog open={open} fullScreen={mobile} onClose={onClose}>
      <LeftActions>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </LeftActions>
      <DialogTitle>{STRINGS.preview.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{STRINGS.preview.desc}</DialogContentText>
        <QRCode
          size={QR_CONFIG.size}
          includeMargin={QR_CONFIG.includeMargin}
          value={src}
          imageSettings={QR_CONFIG.imageSettings}
        />
        <DialogContentText>
          {STRINGS.preview.or}
          <Link href="#" onClick={goToPreview}>
            {STRINGS.preview.clickHere}
          </Link>
          <span
            // text is from constant strings but not user input, should be safe to use directly without sanitise
            dangerouslySetInnerHTML={{ __html: STRINGS.preview.viewOnDevice }}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewDialog;
