import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { useTheme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  AddToQueueOutlined,
  QueuePlayNextOutlined,
  ViewCarouselOutlined,
} from "@material-ui/icons/";
import { ROUTES } from "../../routes/routes";

import {
  Navbar,
  DraftCard,
  CreateDraftDialog,
  JoinDraftDialog,
  PreviewDraftDialog,
} from "./components";
import { Spinner, InfiniteScroll, SideBarContent } from "../../components";
import { NoDraftsContent } from "./components";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  useUserDrafts,
  useDraftInviteCode,
} from "../../services/FirestoreService";
import { useDraft } from "../../services/FirestoreService";

import {
  debounce,
  draftContainsFilter,
  draftOwnedByFilter,
  sortUserDraftsCallback,
} from "../../utils";

const Content = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-template-rows: min-content;
  grid-gap: 1.5rem;
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  margin-left: ${(props) => !props.$mobile && "5rem"};
`;

const INFINITE_SCROLL_CONFIG = {
  load_limit: 12,
  debounce_limit_ms: 500,
};

export default function Home() {
  const matchJoin = useRouteMatch(ROUTES.PROJECT_JOIN);
  const history = useHistory();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const auth = useSelector((state) => state.firebase.auth);
  const userId = auth.uid;

  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortDesc, setSortDesc] = useState(true);
  const [ownedBy, setOwnedBy] = useState("Owned by anyone");
  const [joinCode, setJoinCode] = useState();
  const draftcode = useDraftInviteCode({ code: joinCode });
  const previewDraft = useDraft({ draftId: draftcode?.draftId });
  const [progress, setProgress] = useState(false);
  const [showCreateDraftDialog, setShowCreateDraftDialog] = useState(false);
  const [showJoinDraftDialog, setShowJoinDraftDialog] = useState(false);
  const [showPreviewDraftDialog, setShowPreviewDraftDialog] = useState(false);
  const [limit, setLimit] = useState(INFINITE_SCROLL_CONFIG.load_limit);
  const userDrafts = useUserDrafts({ userId });
  const totalDrafts = userDrafts && Object.keys(userDrafts).length;

  const loading = !isLoaded(userDrafts);

  // handle join url logic
  useEffect(() => {
    if (matchJoin?.path !== ROUTES.join) return;
    setJoinCode(matchJoin.params.code);
    setShowPreviewDraftDialog(true);
  }, [matchJoin]);

  // handle user already joined draft logic
  useEffect(() => {
    if (!previewDraft || !userId || !draftcode || progress) return;
    if (previewDraft.users.includes(userId)) {
      history.push(`${ROUTES.draft}/${draftcode?.draftId}`);
      enqueueSnackbar("Draft already joined.", { variant: "info" });
    }
  }, [previewDraft, userId, history, draftcode, enqueueSnackbar, progress]);

  const onLoadMore = debounce(() => {
    if (!limit) return;
    let newLimit = limit + INFINITE_SCROLL_CONFIG.load_limit;
    if (newLimit >= totalDrafts) {
      // load whole array
      newLimit = undefined;
    }
    setLimit(newLimit);
  }, INFINITE_SCROLL_CONFIG.debounce_limit_ms);

  const config = {
    onLoadMore,
  };

  const openCreateDialog = () => {
    setShowCreateDraftDialog(true);
  };

  const openJoinDialog = () => {
    setShowJoinDraftDialog(true);
  };

  const onConfirmJoinCode = (joinCode) => {
    setJoinCode(joinCode);
    setShowJoinDraftDialog(false);
    setShowPreviewDraftDialog(true);
  };

  const actions = [
    {
      icon: <AddToQueueOutlined />,
      name: "Create a new draft",
      mobileName: "Create",
      onClick: () => {
        openCreateDialog();
      },
    },
    {
      icon: <QueuePlayNextOutlined />,
      mobileName: "Join",
      name: "Join an existing draft",
      onClick: () => {
        openJoinDialog();
      },
    },
  ];

  const sideBarConfig = {
    speedDial: {
      actions,
    },
    tabs: [
      {
        icon: <ViewCarouselOutlined />,
        label: "Drafts",
        route: ROUTES.home,
      },
    ],
  };

  return (
    <div>
      <Navbar
        searchText={searchText}
        setSearchText={setSearchText}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDesc={sortDesc}
        setSortDesc={setSortDesc}
        ownedBy={ownedBy}
        setOwnedBy={setOwnedBy}
      />
      <Content>
        {loading && <Spinner />}
        {!loading && !userDrafts && (
          <>
            <SideBarContent config={sideBarConfig} />
            <NoDraftsContent />
          </>
        )}
        {!loading && userDrafts && (
          <>
            <SideBarContent config={sideBarConfig} />
            <Grid $mobile={mobile}>
              <InfiniteScroll
                size={Object.keys(userDrafts).length}
                max={Object.keys(userDrafts).length}
                config={config}
              >
                {Object.keys(userDrafts)
                  .filter((key) => userDrafts[key] && !userDrafts[key].deleted)
                  .sort(sortUserDraftsCallback(sortBy, sortDesc, userDrafts))
                  .filter((key) =>
                    draftContainsFilter({
                      draft: userDrafts[key],
                      filter: searchText,
                    })
                  )
                  .filter((key) =>
                    draftOwnedByFilter({
                      draft: userDrafts[key],
                      userId,
                      ownedBy,
                    })
                  )
                  .slice(0, limit)
                  .map((key) => (
                    <DraftCard
                      key={key}
                      draft={userDrafts[key]}
                      draftId={key}
                    />
                  ))}
              </InfiniteScroll>
            </Grid>
          </>
        )}
      </Content>
      <CreateDraftDialog
        open={showCreateDraftDialog}
        handleClose={() => setShowCreateDraftDialog(false)}
      />
      <JoinDraftDialog
        open={showJoinDraftDialog}
        handleClose={() => setShowJoinDraftDialog(false)}
        onConfirm={onConfirmJoinCode}
      />
      <PreviewDraftDialog
        draft={previewDraft}
        draftcode={draftcode}
        open={showPreviewDraftDialog}
        handleClose={() => {
          setShowPreviewDraftDialog(false);
          setProgress(false);
        }}
        joinCode={joinCode}
        progress={progress}
        setProgress={setProgress}
      />
    </div>
  );
}
