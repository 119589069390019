import { COLLECTIONS } from "../config/firebase";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { usePermissions } from "./PermissionService";

export const useUser = ({ userId }) => {
  const collection = COLLECTIONS.USERS;
  const config = userId && {
    collection,
    doc: userId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && userId in infos && infos[userId];
  });
};

export const getNameInitials = ({ name }) =>
  name &&
  name
    .split(" ")
    .map((n) => n[0])
    .join("");

export const useUserDraftPermissions = ({ userId, draftId }) => {
  const docId = `${draftId}_${userId}`;
  const collection = COLLECTIONS.USERS_AND_DRAFTS;
  const config = userId && {
    collection,
    doc: docId,
  };
  useFirestoreConnect(config);
  return useSelector(({ firestore: { data } }) => {
    const infos = data[collection];
    return isLoaded(infos) && docId in infos && infos[docId];
  });
};

export const useUserPermissions = ({ userId, draftId }) => {
  const userPermission = useUserDraftPermissions({ userId, draftId });
  const permissions = usePermissions();
  const permissionKey = userPermission?.permissionKey;
  return permissions?.[permissionKey];
};
