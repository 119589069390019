import React, { memo } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

import { useProjectInfo } from "../services/FirestoreService";

const contains = (src, target) => new RegExp(target, "i").test(src);

const isEqual = (p1, p2) =>
  p1.projectId === p2.projectId && p1.filter === p2.filter;

const ProjectCard = ({ projectId, onClick, filter, mobile }) => {
  const info = useProjectInfo(projectId);
  if (!info) return <></>;
  if (filter && !contains(info.name, filter) && !contains(info.brand, filter))
    return <></>;
  const _onClick = () => {
    if (onClick) onClick(projectId, info);
  };
  return (
    <div
      m={1}
      style={{
        padding: 8,
        // width: mobile ? "calc(50%)" : "max(200px, calc(calc(100% - 32px) / 5))",
        // maxWidth: mobile ? "100%" : 240,
      }}
    >
      <Card>
        <CardActionArea onClick={_onClick}>
          <CardMedia style={{ height: 120 }} image={info.imageURL} />
          <CardContent
            style={{
              height: 72,
            }}
          >
            <Typography variant="body1" noWrap>
              {info.name}
            </Typography>
            <Typography variant="body2" noWrap>
              {info.brand}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default memo(ProjectCard, isEqual);
