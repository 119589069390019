import React, { useRef } from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { ViewCarouselOutlined } from "@material-ui/icons";
import { DRAFT_TYPES } from "../../config/drafts";
import { Auth } from "../../App";
import { useDraftPermissionsContext } from "../../context";
import { MobileContent } from "../../components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  useMessageListener,
  usePostMyStoryData,
  usePostMySignageData,
  usePostMyQRData,
  usePostUserPermissions,
  usePostDraftEdit,
} from "../../services/MessageService";
import {
  useIframeUrl,
  checkIsDraftCollaborative,
} from "../../services/DraftService";
import { SideBarContent } from "../../components";
import { ROUTES } from "../../routes/routes";
import STRINGS from "../../strings/general";

const Container = styled(Box)`
  flex: 1;
  overflow: hidden;
`;

const MyIframe = styled.iframe`
  height: 100%;
  width: 100%;
  border-width: 0;
`;

const MyStoriesContent = ({ draftId, draftType, preview }) => {
  const currentUserId = Auth.currentUser.uid;
  const iframe = useRef(null);
  const iframeReady = useMessageListener({
    targetWindow: iframe.current,
    draftId,
  });

  usePostDraftEdit({
    draftId,
    targetWindow: iframe.current,
    iframeReady,
    currentUserId,
  });
  usePostUserPermissions({ targetWindow: iframe.current, iframeReady });
  usePostMyStoryData({
    draftId,
    draftType,
    targetWindow: iframe.current,
    iframeReady,
  });
  const iframeSrc = useIframeUrl({ draftId, draftType, preview });
  const id = DRAFT_TYPES[draftType].iframeId;
  const title = DRAFT_TYPES[draftType].title;
  return (
    <>
      {iframeSrc && (
        <MyIframe id={id} ref={iframe} title={title} src={iframeSrc}></MyIframe>
      )}
    </>
  );
};

const MySignageContent = ({ draftId, draftType, preview }) => {
  const iframe = useRef(null);
  const iframeReady = useMessageListener({
    targetWindow: iframe.current,
    draftId,
  });
  const currentUserId = Auth.currentUser.uid;
  usePostDraftEdit({
    draftId,
    targetWindow: iframe.current,
    iframeReady,
    currentUserId,
  });
  usePostUserPermissions({ targetWindow: iframe.current, iframeReady });
  usePostMySignageData({ draftId, targetWindow: iframe.current, iframeReady });
  const iframeSrc = useIframeUrl({ draftId, draftType, preview });
  return (
    <>
      {iframeSrc && (
        <MyIframe
          id={DRAFT_TYPES.mysignage.iframeId}
          ref={iframe}
          title={DRAFT_TYPES.mysignage.title}
          src={iframeSrc}
        ></MyIframe>
      )}
    </>
  );
};

const MyQRContent = ({ draftId, draftType, preview }) => {
  const currentUserId = Auth.currentUser.uid;
  const iframe = useRef(null);
  const iframeReady = useMessageListener({
    targetWindow: iframe.current,
    draftId,
  });
  usePostDraftEdit({
    draftId,
    targetWindow: iframe.current,
    iframeReady,
    currentUserId,
  });
  usePostUserPermissions({ targetWindow: iframe.current, iframeReady });
  usePostMyQRData({ draftId, targetWindow: iframe.current, iframeReady });
  const iframeSrc = useIframeUrl({ draftId, draftType, preview });
  return (
    <>
      {iframeSrc && (
        <MyIframe
          id={DRAFT_TYPES.myqr.iframeId}
          ref={iframe}
          title={DRAFT_TYPES.myqr.title}
          src={iframeSrc}
        ></MyIframe>
      )}
    </>
  );
};

export const Content = ({ draftType, draftId, preview }) => {
  if (!draftType || !draftId) return <></>;
  if (
    [
      DRAFT_TYPES.mystories.key,
      DRAFT_TYPES.mystories_v2.key,
      DRAFT_TYPES.myguide.key,
    ].includes(draftType)
  ) {
    return (
      <MyStoriesContent
        draftId={draftId}
        draftType={draftType}
        preview={preview}
      />
    );
  }
  if (draftType === DRAFT_TYPES.mysignage.key) {
    return (
      <MySignageContent
        draftId={draftId}
        draftType={draftType}
        preview={preview}
      />
    );
  }
  if (draftType === DRAFT_TYPES.myqr.key) {
    return (
      <MyQRContent draftId={draftId} draftType={draftType} preview={preview} />
    );
  }
};

const EditorTab = ({ draft, draftId }) => {
  const draftType = draft && draft.type;
  const theme = useTheme();
  const isDraftCollaborative = checkIsDraftCollaborative(draft);
  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sideBarConfig = {
    tabs: [
      {
        icon: <ViewCarouselOutlined />,
        label: "Drafts",
        route: ROUTES.home,
      },
    ],
  };

  return (
    <>
      <Container>
        {mobile ? (
          <MobileContent
            title={STRINGS.editor.mobile.title}
            desc={STRINGS.editor.mobile.desc}
          />
        ) : (
          <>
            {!canEdit && isDraftCollaborative && (
              <SideBarContent config={sideBarConfig} />
            )}
            <Content draftType={draftType} draftId={draftId} />
          </>
        )}
      </Container>
    </>
  );
};

export default EditorTab;
