import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Menu,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MoreVert } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { TABS } from "../../../config/config";
import { MyButton } from "../../../components";
import {
  LeaveDraftMenuItem,
  PreviewDraftMenuItem,
  ManageVersionMenuItem,
} from ".";
import {
  useDraftIdContext,
  useDraftPermissionsContext,
} from "../../../context";
import { ROUTES } from "../../../routes/routes";
import { Auth } from "../../../App";
import { useDraftEdit } from "../../../services/FirestoreService";

const TABS_CONFIG = {
  editor: {
    showPublishButton: true,
  },
  team: {
    showPublishButton: false,
  },
};

const RightContainer = styled.div`
  margin-left: auto;
`;

const MyTabs = styled(Tabs)`
  flex: 1;
`;

const MyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const MyToolbar = styled(Toolbar)`
  position: relative;
`;

const TabsContent = ({ tab, url, variant, className, showTeamTab }) => {
  return (
    <MyTabs
      className={className}
      centered={true}
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      variant={variant}
    >
      {TABS.filter((tab) => {
        if (!showTeamTab && tab.value === "team") {
          return false;
        } else {
          return true;
        }
      }).map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          label={tab.label}
          component={Link}
          to={`${url}/${tab.value}`}
        />
      ))}
    </MyTabs>
  );
};

const AbsoluteCenteredTabsContent = styled(TabsContent)`
  position: absolute;
  width: 75%;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 0;
`;

const MyAppBar = ({
  draftAppBarConfig,
  tab,
  url,
  onPublish,
  isDraftCollaborative,
  openPreviewDialog,
  version,
}) => {
  const theme = useTheme();
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const tabsConfig = TABS_CONFIG[tab];
  const currentUserPermissions = useDraftPermissionsContext();
  const currentUserId = Auth.currentUser.uid;
  const draftId = useDraftIdContext();
  const draftEdit = useDraftEdit({ draftId });
  const canEdit =
    currentUserPermissions?.editor?.write &&
    currentUserId === draftEdit?.userId;

  const handleMoreClose = () => {
    setShowMoreMenu(false);
  };

  return (
    <AppBar position="sticky" color="inherit">
      <MyToolbar>
        {draftAppBarConfig && (
          <MyLink to={ROUTES.home}>
            <Typography variant="h6">{draftAppBarConfig.title}</Typography>
          </MyLink>
        )}
        {!mobile && (
          <AbsoluteCenteredTabsContent
            variant="standard"
            url={url}
            tab={tab}
            showTeamTab={isDraftCollaborative}
          />
        )}
        <RightContainer>
          {((tabsConfig?.showPublishButton && canEdit) ||
            !isDraftCollaborative) &&
            !mobile && <MyButton onClick={onPublish} text="Publish" />}
          {draftAppBarConfig?.overflow && (
            <IconButton
              aria-haspopup="true"
              onClick={(e) => setShowMoreMenu(e.currentTarget)}
              color="inherit"
            >
              <MoreVert />
            </IconButton>
          )}
        </RightContainer>
        {draftAppBarConfig?.overflow &&
          draftAppBarConfig.overflow.length > 0 && (
            <Menu
              anchorEl={showMoreMenu}
              keepMounted
              open={Boolean(showMoreMenu)}
              onClose={handleMoreClose}
            >
              {draftAppBarConfig?.overflow.map((menuKey, i) => {
                // iterate through each item and do different checking and render
                switch (menuKey) {
                  case "leaveDraft":
                    return (
                      <div key={`overflow-${i}`}>
                        <LeaveDraftMenuItem />
                      </div>
                    );
                  case "previewDraft":
                    if (openPreviewDialog)
                      // onOpen defined
                      return (
                        <div key={`overflow-${i}`}>
                          <PreviewDraftMenuItem
                            action={() => {
                              openPreviewDialog();
                              handleMoreClose();
                            }}
                            version={version}
                          />
                        </div>
                      );
                    break;
                  case "upgradeDraft":
                    return (
                      <div key={`overflow-${i}`}>
                        <ManageVersionMenuItem
                          onClose={handleMoreClose}
                        />
                      </div>
                    );
                  default:
                    break;
                }
                return <></>;
              })}
            </Menu>
          )}
      </MyToolbar>
      {mobile && (
        <TabsContent
          variant="fullWidth"
          url={url}
          tab={tab}
          showTeamTab={isDraftCollaborative}
        />
      )}
    </AppBar>
  );
};

export default MyAppBar;
