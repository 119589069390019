import React from "react";
import { DraftPermissionsProvider, DraftIdProvider } from "../../context";
import { useDraft } from "../../services/FirestoreService";
import { Content } from "../../tabs/EditorTab";
import { useParams } from "react-router-dom";

const Preview = () => {
  let { draftId } = useParams();
  const draft = useDraft({ draftId });
  const draftType = draft?.type;
  return (
    <DraftPermissionsProvider draftId={draftId}>
      <DraftIdProvider draftId={draftId}>
        <Content draftType={draftType} draftId={draftId} preview={true} />
      </DraftIdProvider>
    </DraftPermissionsProvider>
  );
};

export default Preview;
