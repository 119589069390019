import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { Auth } from "../../../App";
import { useUserPermissions } from "../../../services/UserService";
import { ROUTES } from "../../../routes/routes";
import { duplicateDraft } from "../../../services/ApiService";
import { getDraftTemplateVersion } from "../../../services/DraftService";
import { useAdminModeContext } from "../../../context";
import OverflowTypography from "../components/OverflowTypography";

const MyCard = styled(Card)`
  height: 25rem;
  position: relative;
`;

const MyCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MyCardMediaContainer = styled(Box)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey.dark};
    flex: 1;
    overflow: hidden;
    width: 100%;
  `}
`;

const EmptyMedia = styled(Box)`
  ${({ theme }) => `
    flex: 1;
    width: 100%;
    background-color: ${theme.palette.grey.dark};
  `}
`;

const MyCardMedia = styled(CardMedia)`
  width: 100%;
  height: 100%;
  transform: scale(${(props) => props.imagescale / 100});
`;

const MyCardContent = styled(CardContent)`
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 2rem;
`;

const MyChip = styled(Chip)`
  margin-top: 1rem;
  cursor: pointer;
`;

const MenuItemText = styled.span`
  margin-right: 0.5rem;
`;

const CardOverflowMenu = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const MyLinearProgress = styled(LinearProgress)`
  position: absolute;
  top: 0;
  width: 100%;
`;

const MyMoreVert = styled(MoreVert)`
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.7));
`;

const ProjectNameContainer = styled(OverflowTypography)`
  // based on myproject
  padding-top: 8px;
  color: #000000;
  line-height: 1.43;
`;

const ClientNameContainer = styled(OverflowTypography)`
  // based on myproject
  font-size: 0.875rem;
  font-weight: 400;
  color: #5f5f5f;
  line-height: 1.43;
`;

// content: {
//   paddingLeft: theme.spacing(2),
//   paddingRight: theme.spacing(2),
// },
// name: {
//   paddingTop: theme.spacing(1),
// },

const DraftCard = ({ draft, draftId }) => {
  const adminMode = useAdminModeContext()[0];
  const {
    clientName,
    imageScale,
    imageUrl,
    name,
    type,
    // lastModifiedAt,
    // lastPublishedAt,
  } = draft;
  const history = useHistory();
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const currentUserId = Auth.currentUser.uid;
  const currentUserPermissions = useUserPermissions({
    userId: currentUserId,
    draftId,
  });
  const canEdit = currentUserPermissions?.userPermissions?.write;

  const goToDraft = () => {
    if (loading) return;
    history.push(`${ROUTES.draft}/${draftId}`);
  };

  const handleMoreClose = () => {
    setShowMoreMenu(false);
  };

  const onClickDuplicate = async (upgrade) => {
    setLoading(true);
    setShowMoreMenu(false);
    try {
      await duplicateDraft({
        draftId,
        draftType: draft.type,
        upgrade,
      });
      // snackbar
      enqueueSnackbar("Draft duplicated.", { variant: "success" });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("An error has occured!", { variant: "error" });
      // snackbar
    } finally {
      setLoading(false);
    }
  };

  const ver = getDraftTemplateVersion(draft);
  const chipLabel = adminMode && ver ? `${type} ${ver.version}` : type;

  return (
    <MyCard>
      <MyCardActionArea onClick={goToDraft} component="div" disabled={loading}>
        {imageUrl ? (
          <MyCardMediaContainer>
            <MyCardMedia image={imageUrl} imagescale={imageScale} />
          </MyCardMediaContainer>
        ) : (
          <EmptyMedia />
        )}
        <MyCardContent>
          <ProjectNameContainer variant="h6" noWrap>
            {name}
          </ProjectNameContainer>

          <ClientNameContainer variant="h6" noWrap>
            {clientName}
          </ClientNameContainer>

          <MyChip label={chipLabel} />
        </MyCardContent>
      </MyCardActionArea>
      <CardOverflowMenu
        disabled={loading}
        aria-haspopup="true"
        onClick={(e) => setShowMoreMenu(e.currentTarget)}
        color="inherit"
      >
        <MyMoreVert htmlColor="#FFFFFF" />
      </CardOverflowMenu>
      <Menu
        anchorEl={showMoreMenu}
        keepMounted
        open={Boolean(showMoreMenu)}
        onClose={handleMoreClose}
      >
        <MenuItem onClick={() => onClickDuplicate(false)} disabled={!canEdit}>
          <MenuItemText>Duplicate</MenuItemText>
        </MenuItem>
        {/* <MenuItem onClick={() => onClickDuplicate(true)}>
          <MenuItemText>Duplicate and upgrade</MenuItemText>
        </MenuItem>
        <MenuItem onClick={onClickDelete}>
          <MenuItemText>Delete</MenuItemText>
        </MenuItem> */}
      </Menu>
      {loading && <MyLinearProgress />}
    </MyCard>
  );
};

export default DraftCard;
