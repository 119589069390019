import React, { useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";

import { ROUTES } from "../../routes/routes";
import { ErrorDialog, Spinner, ProgressDialog } from "../../components";
import { useDraft, useQR, useStory } from "../../services/FirestoreService";
import {
  useSignage,
  checkSignCovers24Hours,
} from "../../services/SignageService";
import {
  checkIsDraftCollaborative,
  publish,
  getDraftTemplateVersion,
} from "../../services/DraftService";
import {
  startPublishMessage,
  endPublishMessage,
} from "../../services/MessageService";
import EditorTab from "../../tabs/EditorTab";
import TeamTab from "../../tabs/TeamTab";
import {
  SelectProjectPanel,
  MyAppBar,
  DraftEditHandler,
  PreviewDialog,
} from "./components";
import { DRAFT_TYPES } from "../../config/drafts";
import GENERAL_STRINGS from "../../strings/general";
import {
  DraftPermissionsProvider,
  DraftIdProvider,
  useConfirmDialogContext,
} from "../../context";
import { TABS } from "../../config/config";
import {
  getDaftAppBarConfig,
  checkForDraftErrors,
  openPreviewWindow,
} from "./utils";

const DEFAULT_TAB = "editor";

const Draft = () => {
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showProjectPanel, setShowProjectPanel] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [publishInfo, setPublishInfo] = useState();
  const [errorInfo, setErrorInfo] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  let { path, url } = useRouteMatch();
  let location = useLocation();
  let { draftId } = useParams();
  const draft = useDraft({ draftId });
  const story = useStory({ draftId, draftType: draft?.type });
  const qr = useQR({ draftId });
  const signage = useSignage({ draftId });
  const confirm = useConfirmDialogContext();
  const ver = getDraftTemplateVersion(draft);

  const isDraftCollaborative = checkIsDraftCollaborative(draft);
  const draftAppBarConfig = getDaftAppBarConfig(draft);
  const publishSuccessMessage = draftAppBarConfig?.publish?.success;
  let tab = location.pathname.split("/").pop();
  if (!TABS.map((tab) => tab.value).includes(tab)) {
    tab = DEFAULT_TAB;
  }

  const onOpenPreviewDialog = () => {
    if (mobile) {
      return openPreviewWindow(draftId);
    }
    setShowPreviewDialog(true);
  };

  const onClosePreviewDialog = () => {
    setShowPreviewDialog(false);
  };

  const onProjectPanelClose = () => {
    setShowProjectPanel(false);
  };

  const checkForConfirmPublish = () => {
    if (draft.type === DRAFT_TYPES.mysignage.key) {
      const playtimesArray = signage.chapters
        .map((chapter) => chapter.playtimes && Object.values(chapter.playtimes))
        .reduce((acc, val) => acc.concat(val), []);
      const result = checkSignCovers24Hours(playtimesArray, "00:00");
      return !result;
    }
  };

  const onClickPublishButton = async () => {
    setShowErrorDialog(false);
    setErrorInfo();

    let draftError;

    // qr and story are different types so cannot co-exist
    var checkDraft = qr || story;
    if (checkDraft) {
      draftError = checkForDraftErrors(draft, checkDraft);
      if (draftError) {
        setErrorInfo(draftError);
        setShowErrorDialog(true);
        setShowProjectPanel(false);
        return;
      }
    }

    const isConfirmPublish = checkForConfirmPublish();
    if (isConfirmPublish) {
      try {
        await confirm({
          title: draftAppBarConfig.publish.confirm.title,
          message: draftAppBarConfig.publish.confirm.desc,
        });
      } catch (e) {
        return;
      }
    }
    setPublishInfo({
      projectId: "",
      name: "",
      brand: "",
    });
    setShowProjectPanel(true);
  };

  const onSelectProject = async (id, info) => {
    console.debug("onSelect", id);
    setPublishInfo({
      projectId: id,
      name: info.name,
      brand: info.brand,
    });
    try {
      await confirm({
        title: GENERAL_STRINGS.selectProject.projectTitle
          .replace("{name}", info.name)
          .replace("{brand}", info.rand ? `(${info.brand})` : ""),
        message: GENERAL_STRINGS.selectProject.confirm.replace(
          "{name}",
          info.name
        ),
      });
    } catch (e) {
      return;
    }
    try {
      setShowProgressDialog(true);
      onStartPublishMessage();
      const result = await publish({
        draftId,
        projectId: id,
        draftType: draft.type,
      });
      console.debug(result);
      enqueueSnackbar(publishSuccessMessage, { variant: "success" });
    } catch (e) {
      enqueueSnackbar(GENERAL_STRINGS.publish.error, { variant: "error" });
    } finally {
      setShowProgressDialog(false);
      onEndPublishMessage();
      setShowProjectPanel(false);
    }
  };

  const onErrorConfirm = () => {
    setShowErrorDialog(false);
  };

  const onStartPublishMessage = () => {
    const targetWindow = document.getElementById(
      DRAFT_TYPES[draft.type].iframeId
    );
    if (!targetWindow) return;
    startPublishMessage({
      targetWindow,
    });
  };

  const onEndPublishMessage = () => {
    const targetWindow = document.getElementById(
      DRAFT_TYPES[draft.type].iframeId
    );
    if (!targetWindow) return;
    endPublishMessage({
      targetWindow,
    });
  };

  const loading = isDraftCollaborative === undefined;

  return (
    <DraftPermissionsProvider draftId={draftId}>
      <DraftIdProvider draftId={draftId}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <MyAppBar
              draftAppBarConfig={draftAppBarConfig}
              tab={tab}
              url={url}
              onPublish={onClickPublishButton}
              isDraftCollaborative={isDraftCollaborative}
              openPreviewDialog={onOpenPreviewDialog}
              version={ver}
            />
            <Switch>
              <Route path={`${path}${ROUTES.editor}`}>
                <EditorTab draft={draft} draftId={draftId} />
              </Route>
              <Route path={`${path}${ROUTES.team}`}>
                {isDraftCollaborative ? (
                  <TeamTab draft={draft} draftId={draftId} />
                ) : (
                  <Redirect to={`${ROUTES.draft}/${draftId}${ROUTES.editor}`} />
                )}
              </Route>
              <Route path="*">
                <Redirect to={`${ROUTES.draft}/${draftId}${ROUTES.editor}`} />
              </Route>
            </Switch>
          </>
        )}
        <SelectProjectPanel
          open={showProjectPanel}
          onClose={onProjectPanelClose}
          onSelect={onSelectProject}
        />
        <ErrorDialog
          open={showErrorDialog}
          title={errorInfo?.title}
          description={errorInfo?.desc}
          onConfirm={onErrorConfirm}
          mobile={mobile}
        />
        <PreviewDialog
          open={showPreviewDialog}
          onClose={onClosePreviewDialog}
        />
        <ProgressDialog
          open={showProgressDialog}
          config={{
            title: GENERAL_STRINGS.publish.title.replace(
              "{name}",
              publishInfo?.name
            ),
            desc: GENERAL_STRINGS.publish.desc,
          }}
        />
        <DraftEditHandler draftId={draftId} />
      </DraftIdProvider>
    </DraftPermissionsProvider>
  );
};

export default Draft;
