import React, { useState, useContext, useCallback, createContext } from "react";
import { Auth } from "./App";
import { useUserPermissions } from "./services/UserService";
import { ConfirmDialog } from "./components";

const DraftPermissionsContext = createContext();
const DraftIdContext = createContext();
const ConfirmDialogContext = createContext();
const AdminModeContext = createContext();

export const DraftPermissionsProvider = ({ draftId, children }) => {
  const currentUserId = Auth.currentUser.uid;
  const currentUserPermissions = useUserPermissions({
    userId: currentUserId,
    draftId,
  });
  return (
    <DraftPermissionsContext.Provider value={currentUserPermissions}>
      {children}
    </DraftPermissionsContext.Provider>
  );
};

export const useDraftPermissionsContext = () => {
  const context = useContext(DraftPermissionsContext);
  return context;
};

export const DraftIdProvider = ({ draftId, children }) => {
  return (
    <DraftIdContext.Provider value={draftId}>
      {children}
    </DraftIdContext.Provider>
  );
};

export const useDraftIdContext = () => {
  const context = useContext(DraftIdContext);
  return context;
};

export const ConfirmDialogProvider = ({ children }) => {
  const [props, setProps] = useState();
  const [resolveReject, setResolveReject] = useState([]);
  const [resolve, reject] = resolveReject;

  const showConfirm = useCallback(
    ({ title, message }) =>
      new Promise((resolve, reject) => {
        setProps({ title, message });
        setResolveReject([resolve, reject]);
      }),
    []
  );

  const onClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const onCancel = useCallback(() => {
    if (reject) reject();
    onClose();
  }, [reject, onClose]);

  const onConfirm = useCallback(() => {
    if (resolve) resolve();
    onClose();
  }, [resolve, onClose]);
  return (
    <>
      <ConfirmDialogContext.Provider value={showConfirm}>
        {children}
      </ConfirmDialogContext.Provider>
      <ConfirmDialog
        open={resolveReject.length === 2}
        title={props?.title}
        description={props?.message}
        onConfirm={onConfirm}
        onClose={onCancel}
      />
    </>
  );
};

export const useConfirmDialogContext = () => {
  const context = useContext(ConfirmDialogContext);
  return context;
};

export const AdminModeProvider = ({ children }) => {
  const adminMode = useState(false);
  return (
    <AdminModeContext.Provider value={adminMode}>
      {children}
    </AdminModeContext.Provider>
  );
};

export const useAdminModeContext = () => {
  const context = useContext(AdminModeContext);
  return context;
};
