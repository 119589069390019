import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Button,
  CardContent,
  Box,
  Typography,
  FormGroup,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from "@material-ui/core";
import styled from "styled-components";
import { CasinoOutlined } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { PERMISSIONS } from "../../../services/PermissionService";
import { createDraftCode } from "../../../services/ApiService";
import { Auth } from "../../../App";
import { useDraftIdContext } from "../../../context";

const MyCard = styled(Card)`
  height: 100%;
  flex: 1;
  margin-left: ${(props) => !props.$mobile && "1rem"};
  margin-top: ${(props) => props.$mobile && "1rem"};
  overflow: initial;
`;

const Container = styled.div`
  padding: 1rem;
`;

const MyButton = styled(Button)`
  text-transform: none;
  width: 9rem;
  font-size: 1rem;
`;

const CardTitle = styled.span`
  font-weight: 600;
`;

const CardDesc = styled(Typography)`
  margin-bottom: 1rem;
`;

const CreateInviteCodesCard = () => {
  const [name, setName] = useState("");
  const [defaultRole, setDefaultRole] = useState(PERMISSIONS.viewer.key); // index as value
  const [progress, setProgress] = useState(false);
  const currentUserId = Auth.currentUser.uid;
  const draftId = useDraftIdContext();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onGenerate = async () => {
    setProgress(true);

    try {
      await createDraftCode({
        userId: currentUserId,
        draftId,
        name,
        defaultRole,
      });
      enqueueSnackbar("Invite code created!", { variant: "success" });
      setProgress(false);
      setName("");
      setDefaultRole(PERMISSIONS.viewer.key);
    } catch (e) {
      enqueueSnackbar("An error has occurred!", { variant: "error" });
      setProgress(false);
    }
  };

  const title = <CardTitle>Invite Code</CardTitle>;
  const disableButton = !name || !defaultRole || progress;
  const button = (
    <MyButton variant="contained" onClick={onGenerate} disabled={disableButton}>
      Generate
    </MyButton>
  );

  const icon = <CasinoOutlined />;

  const onRoleChange = (e) => setDefaultRole(e.target.value);

  return (
    <MyCard $mobile={mobile}>
      {progress && <LinearProgress />}
      <Container>
        <CardHeader title={title} action={button} avatar={icon} />
        <CardContent>
          <CardDesc variant="body2">
            Generate a new invite code to share.
          </CardDesc>
          <FormGroup>
            <Box p={1}>
              <FormControl fullWidth>
                <TextField
                  variant="filled"
                  label="Name"
                  value={name}
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box p={1}>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Default Role</InputLabel>
                <Select
                  value={defaultRole}
                  onChange={onRoleChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Object.entries(PERMISSIONS)
                    .filter(([key, value]) => key !== "owner")
                    .sort(([aKey, aValue], [bKey, bValue]) => {
                      return aValue.order - bValue.order;
                    })
                    .map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={value.key}>
                          {value.text}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </FormGroup>
        </CardContent>
      </Container>
    </MyCard>
  );
};

export default CreateInviteCodesCard;
