import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import styled from "styled-components";

const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default Spinner;
