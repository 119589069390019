export const PROTOCOL = window.location.protocol;
export const HOST = window.location.host;

export const ROUTES = {
  home: "/",
  login: "/login",
  mystories: "/mystories",
  mysignage: "/mysignage",
  myqr: "/myqr",
  debug: "/debug",
  draft: "/draft",
  editor: "/editor",
  preview: "/preview",
  team: "/team",
  join: "/join/:code",
};

export const URL_PARAMS = {
  draftId: "/:draftId",
};

export const getProjectJoinRoute = (code) => ROUTES.join.replace(":code", code);

export const getProjectJoinUrl = (code) =>
  `${PROTOCOL}//${HOST}${getProjectJoinRoute(code)}`;

export const getDraftRoute = (draftId) => `${ROUTES.draft}/${draftId}`;
