import React from "react";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import STRINGS from "../../../strings/general";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
  },
}));

const RestoreDraftContent = ({
  version,
  restore,
  onChangeRestore,
  disabled,
}) => {
  const classes = useStyles();

  const onChange = () => {
    onChangeRestore(!restore);
  };

  if (!version) return <></>;

  return (
    <div className={classes.row}>
      <span className={classes.grow}>{`${STRINGS.draft.restoreCaption} ${version}`}</span>
      <Checkbox
        checked={restore}
        onChange={onChange}
        color="primary"
        disabled={disabled}
      />
    </div>
  );
};

export default RestoreDraftContent;
