const HOME_STRINGS = {
  header: "Creator",
  create_dialog: {
    title: "What would you like to create?",
    success: "Draft created successfully.",
    fail: "Oh no, something went wrong!",
  },
  search: {
    placeholder: "Search...",
  },
  welcome: {
    title: "Welcome to the Creator tool!",
    subtitle: "Hit the orange icon to get started.",
  },
  logout: "Logout",
  created: "Created",
  name: "Name",
  brand: "Brand",

  create: {
    title: "Creating a new MyStory",
    desc: "Do not close or refresh this page",
    success: "Story created!",
    fail: "An error has occured!",
  },
};

export default HOME_STRINGS;
