import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

import { MyButton, ProjectCard, PullUpPanel } from ".";

import { listProjects } from "../services/DraftService";
import { useMobileLayout } from "../services/UiService";
import STRINGS from "../strings/general";
import AUTH_STRINGS from "../strings/auth";

import { MYPROJECTS_URL } from "../config/config";

// open = show/hide dialog
// onClose = close dialog
// onSelect = project selected: (id, info) => {}
const SelectProjectPanel = ({ open, onClose, onSelect }) => {
  const [projects, setProjects] = useState(null);
  const [filter, setFilter] = useState();
  const [error, setError] = useState(false);
  const mobile = useMobileLayout();
  const { enqueueSnackbar } = useSnackbar();

  const showSearch = projects?.length > 0;

  // load projects on open, clear on close
  useEffect(() => {
    if (open) {
      listProjects()
        .then((res) => {
          setProjects(res);
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar(AUTH_STRINGS.error, { variant: "error" });
          setError(true);
        });
    } else {
      setError(false);
      setFilter();
      setProjects(null);
    }
  }, [open, enqueueSnackbar]);

  // update filter
  const onFilter = (val) => {
    setFilter(val);
  };

  // project selected
  const onClick = (id, info) => {
    if (onSelect) onSelect(id, info);
  };

  const onClickMyProjects = () => {
    window.open(MYPROJECTS_URL);
  };

  const projectsContent = (
    <div
      style={{
        padding: 8,
        display: "grid",
        "grid-template-columns": "repeat(auto-fill, minmax(240px, 1fr))",
        "grid-template-rows": "min-content",
        width: "100%",
        overflowY: "auto",
      }}
    >
      {projects?.map((id) => (
        <ProjectCard
          key={id}
          projectId={id}
          onClick={onClick}
          filter={filter}
          mobile={mobile}
        />
      ))}
    </div>
  );

  const emptyContent = (
    <div
      style={{
        padding: 16,
      }}
    >
      <Typography variant="body1">
        {STRINGS.selectProject.noProjectTitle}
      </Typography>
      <Typography variant="body2">
        {STRINGS.selectProject.noProjectDesc}
      </Typography>
      <div
        style={{
          padding: 8,
        }}
      >
        <MyButton
          onClick={onClickMyProjects}
          text={STRINGS.selectProject.myprojects}
        />
      </div>
    </div>
  );

  const errorContent = (
    <div
      style={{
        padding: 16,
      }}
    >
      <Typography variant="body1">
        {STRINGS.selectProject.errorTitle}
      </Typography>
      <Typography variant="body2">{STRINGS.selectProject.errorDesc}</Typography>
    </div>
  );

  const loadingContent = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </div>
  );

  const children = error // if there is an error
    ? errorContent
    : projects?.length // if there is project list
    ? projectsContent
    : projects?.length === 0 // if no project
    ? emptyContent
    : loadingContent; // otherwise still loading

  const config = {
    title: STRINGS.selectProject.title,
    onClose,
    onSearch: showSearch && onFilter,
  };

  return (
    <PullUpPanel open={open} config={config} strings={{}}>
      {children}
    </PullUpPanel>
  );
};

export default SelectProjectPanel;
