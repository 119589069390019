import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  checkAuthStatus,
  getXssToken,
  ssoLogout,
  redirectToAccounts,
} from "../services/AuthService";
import { Spinner } from "../components";
import { Redirect } from "react-router-dom";
import { ROUTES } from "./routes";
import { useSnackbar } from "notistack";
import AUTH_STRINGS from "../strings/auth";

// A wrapper for <Route> that redirects to the accounts.myplayer.
// screen if the user is not logged in via firebase

const PrivateRoute = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(false);
  const auth = useSelector((state) => state.firebase.auth);
  const userAuthenticatedByFirebase = !auth.isEmpty;
  const emailVerified = auth?.emailVerified;

  useEffect(() => {
    if (emailVerified === false) redirectToAccounts();
  }, [emailVerified])

  useEffect(() => {
    mounted.current = true;

    const showErrorMessage = () => {
      enqueueSnackbar(AUTH_STRINGS.firebase_auth_error, { variant: "warning" });
    };

    const handleAuth = async () => {
      if (userAuthenticatedByFirebase) {
        const xssToken = await getXssToken();
        if (!xssToken) {
          await ssoLogout();
          return;
        }
        if (mounted.current) setIsAuthenticated(true);
      } else {
        try {
          await checkAuthStatus();
        } catch {
          if (mounted.current) {
            setIsAuthenticated(false);
            showErrorMessage();
          }
        }
      }
    };
    handleAuth();

    return () => {
      mounted.current = false;
    };
  }, [userAuthenticatedByFirebase, enqueueSnackbar]);

  if (isAuthenticated === undefined) {
    return <Spinner />;
  }
  
  // check email verified
  if (emailVerified === false) return <></>;

  if (isAuthenticated === false) {
    return <Redirect to={ROUTES.login} />;
  }
  return props.children;
};

export default PrivateRoute;
