import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { COLLECTIONS } from "../config/firebase";

const useSignageAndChapters = ({ draftId }) => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFTS_AND_SIGNAGE,
      doc: draftId,
      subcollections: [{ collection: COLLECTIONS.CHAPTERS }],
      storeAs: COLLECTIONS.SIGNAGE_AND_CHAPTERS,
      where: ["deleted", "!=", true],
    },
  ]);
  const chapters = useSelector(
    (state) => state.firestore.data.signage_and_chapters
  );
  return chapters;
};

const useDraftsAndSignage = ({ draftId }) => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFTS_AND_SIGNAGE,
      doc: draftId,
    },
  ]);

  const drafts_and_signage = useSelector(
    (state) => state.firestore.data.drafts_and_signage
  );
  return drafts_and_signage;
};

export const useSignage = ({ draftId }) => {
  const drafts_and_signage = useDraftsAndSignage({ draftId });
  const chapters = useSignageAndChapters({ draftId });
  const [signage, setSignage] = useState();
  useEffect(() => {
    if (!draftId || !chapters || !drafts_and_signage) return;
    const signage = {};
    Object.assign(signage, drafts_and_signage[draftId]);
    signage.chapters = [];
    if (signage.chapterOrder && signage.chapterOrder.length) {
      signage.chapterOrder.forEach((chapterId, index) => {
        if (!chapters[chapterId]) return;
        const chapter = {};
        Object.assign(chapter, chapters[chapterId]);
        if (chapter.playtime) {
          chapter.playtime = chapter.playtime.slice();
        }
        chapter.id = chapterId;
        signage.chapters[index] = chapter;
        signage.chapters[index].id = chapterId;
      });
      setSignage(signage);
    }
  }, [draftId, drafts_and_signage, chapters]);

  return signage;
};

export const checkSignCovers24Hours = (playtimesArray, timeCovered) => {
  const timeCoveredArray = timeCovered.split(":");
  const timeCoveredHours = parseInt(timeCoveredArray[0]);
  const timeCoveredMinutes = parseInt(timeCoveredArray[1]);

  // start at 00:00, check if any playtime starts at 00:00, then get the latest endTime out of the matches
  // start at matched endTime, check if any playtime startTime is equal or less than matched endtime, then get latest endTime out of matches
  // keep going until either 24:00 is reached or no playtime startTime is matched
  let matchedPlaytimesArray = [];
  playtimesArray.forEach((playtime) => {
    if (!playtime) return;
    const startTimeArray = playtime.startTime.split(":");
    const startTimeHours = parseInt(startTimeArray[0]);
    const startTimeMinutes = parseInt(startTimeArray[1]);

    if (
      startTimeHours === timeCoveredHours &&
      startTimeMinutes <= timeCoveredMinutes
    ) {
      matchedPlaytimesArray.push(playtime);
    }

    if (
      startTimeHours < timeCoveredHours &&
      startTimeMinutes <= timeCoveredMinutes
    ) {
      matchedPlaytimesArray.push(playtime);
    }
  });

  if (matchedPlaytimesArray.length === 0) return false;

  const reducer = (previousValue, currentValue) => {
    // return which endTime is later
    const currentValueEndTimeArray = currentValue.endTime.split(":");
    const curretValueEndTimeHours = parseInt(currentValueEndTimeArray[0]);
    const curretValueEndTimeMinutes = parseInt(currentValueEndTimeArray[1]);

    const previousValueEndTimeArray = previousValue.endTime.split(":");
    const previousValueEndTimeHours = parseInt(previousValueEndTimeArray[0]);
    const previousValueEndTimeMinutes = parseInt(previousValueEndTimeArray[1]);

    if (curretValueEndTimeHours > previousValueEndTimeHours)
      return currentValue;

    if (
      previousValueEndTimeHours === curretValueEndTimeHours &&
      previousValueEndTimeMinutes < curretValueEndTimeMinutes
    ) {
      return currentValue;
    } else {
      return previousValue;
    }
  };

  const latestMatchedPlaytime = matchedPlaytimesArray.reduce(reducer);
  if (latestMatchedPlaytime.endTime === "24:00") {
    return true;
  } else {
    // filter out matchedPlaytimesArray as we already have latest time covered from them
    const matchedPlaytimesIdsArray = matchedPlaytimesArray.map(
      (matchedPlaytime) => matchedPlaytime.id
    );
    const newPlaytimesArray = playtimesArray.filter(
      (playtime) => !matchedPlaytimesIdsArray.includes(playtime.id)
    );
    return checkSignCovers24Hours(
      newPlaytimesArray,
      latestMatchedPlaytime.endTime
    );
  }
};
