import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { useDraftPermissionsContext } from "../../context";
import {
  TeamMemberCard,
  TeamMemberDialog,
  InviteCodeDrawer,
} from "./components";
import {
  updateUserDraftRole,
  removeUserFromDraft,
} from "../../services/ApiService";
import { useSnackbar } from "notistack";
import { ShareOutlined, ViewCarouselOutlined } from "@material-ui/icons";
import { SideBarContent } from "../../components";
import { ROUTES } from "../../routes/routes";

const Container = styled.div`
  flex: 1;
  margin-left: ${(props) => (props.$mobile ? 0 : "calc(1rem + 56px)")};
`;

const TeamContainer = styled.div`
  display: flex;
  padding: ${(props) => (props.$mobile ? "1rem" : "2rem")};
  flex-wrap: wrap;
`;

const TeamTab = ({ draft, draftId }) => {
  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userIds = draft?.users;
  const [selectedUserId, setSelectedUserId] = useState();
  const [showTeamMemberDialog, setShowTeamMemberDialog] = useState(false);
  const [savingTeamMemberId, setSavingTeamMemberId] = useState();
  const [showInviteCodeDrawer, setShowInviteCodeDrawer] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onCardClick = (userId) => {
    setSelectedUserId(userId);
    setShowTeamMemberDialog(true);
  };

  const onClose = () => {
    setShowTeamMemberDialog(false);
  };

  const onConfirm = async (userId, newPermissionKey) => {
    setSavingTeamMemberId(userId);
    setShowTeamMemberDialog(false);
    try {
      await updateUserDraftRole({
        userId,
        draftId,
        permissionKey: newPermissionKey,
      });
      enqueueSnackbar("User role updated!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    } finally {
      setSavingTeamMemberId(null);
    }
  };

  const onRemoveUser = async (userId) => {
    setSavingTeamMemberId(userId);
    setShowTeamMemberDialog(false);
    try {
      await removeUserFromDraft({
        userId,
        draftId,
      });
      enqueueSnackbar("User removed!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    } finally {
      setSavingTeamMemberId(null);
    }
  };

  const sideBarConfig = {
    fab: canEdit && {
      color: "primary",
      onClick: () => setShowInviteCodeDrawer(true),
      icon: <ShareOutlined />,
    },
    tabs: [
      {
        icon: <ViewCarouselOutlined />,
        label: "Drafts",
        route: ROUTES.home,
      },
    ],
  };

  return (
    <>
      <SideBarContent config={sideBarConfig} />
      <Container $mobile={mobile}>
        <TeamContainer $mobile={mobile}>
          {userIds &&
            userIds.map((userId) => (
              <TeamMemberCard
                editMode={false}
                onClick={onCardClick}
                key={userId}
                userId={userId}
                draftId={draftId}
                progress={savingTeamMemberId === userId}
                mobile={mobile}
              />
            ))}
        </TeamContainer>
      </Container>
      <TeamMemberDialog
        open={showTeamMemberDialog}
        onClose={onClose}
        userId={selectedUserId}
        draftId={draftId}
        onConfirm={onConfirm}
        onRemoveUser={onRemoveUser}
      />
      <InviteCodeDrawer
        open={showInviteCodeDrawer}
        onClose={() => setShowInviteCodeDrawer(false)}
      />
    </>
  );
};

export default TeamTab;
