import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import { Auth } from "../../../App";
import { RequestSentDialog, RequestReceivedDialog } from ".";

import {
  updateDraftAndRequestsDoc,
  acceptDraftSessionRequest,
  useHandleDraftRequestTimer,
  useHandleDraftRequestTimeout,
} from "../../../services/DraftService";
import { REQUEST_STATES } from "../../../config/config";
import {
  useDraftSessionRequest,
  useDraftEdit,
} from "../../../services/FirestoreService";

const DraftEditHandler = ({ draftId }) => {
  const currentUserId = Auth.currentUser.uid;
  const { enqueueSnackbar } = useSnackbar();
  const [showSentRequestDialog, setShowSentRequestDialog] = useState(false);
  const [showRecievedRequestDialog, setShowReceivedRequestDialog] =
    useState(false);

  const draftSessionRequest = useDraftSessionRequest({ draftId });
  const draftEdit = useDraftEdit({ draftId });
  const timerActive =
    currentUserId &&
    (currentUserId === draftSessionRequest?.userId ||
      currentUserId === draftEdit?.userId);
  const remainingRequestTime = useHandleDraftRequestTimer({
    draftSessionRequest,
    timerActive,
  });

  const onAcceptRequest = async () => {
    await acceptDraftSessionRequest({
      draftId,
      requestorUserId: draftSessionRequest.userId,
      requestorName: draftSessionRequest.name,
    });
    setShowReceivedRequestDialog(false);
  };

  useHandleDraftRequestTimeout({
    remainingRequestTime,
    draftSessionRequest,
    onAcceptRequest,
    timerActive,
  });

  const onCancelRequest = async () => {
    const data = {
      state: REQUEST_STATES.rejected,
    };
    await updateDraftAndRequestsDoc({
      draftId,
      data,
    });
    setShowSentRequestDialog(false);
  };

  const onRejectRequest = async () => {
    const data = {
      state: REQUEST_STATES.rejected,
    };
    await updateDraftAndRequestsDoc({
      draftId,
      data,
    });
    setShowReceivedRequestDialog(false);
  };

  useEffect(() => {
    if (!currentUserId || !draftEdit || !draftSessionRequest) return;

    const fulfillRequest = () => {
      return updateDraftAndRequestsDoc({
        draftId: draftSessionRequest.draftId,
        data: { state: REQUEST_STATES.fulfilled },
      });
    };

    // requester
    if (currentUserId === draftSessionRequest.userId) {
      if (draftSessionRequest.state === REQUEST_STATES.requested) {
        setShowSentRequestDialog(true);
        return;
      }
      if (
        draftSessionRequest.state === REQUEST_STATES.accepted &&
        showSentRequestDialog
      ) {
        setShowSentRequestDialog(false);
        // already fulfilled - stop snackbar showing twice
        if (draftEdit.userId === draftSessionRequest.userId) return;
        enqueueSnackbar("Edit request accepted", { variant: "success" });
        fulfillRequest();
        return;
      }
      if (
        draftSessionRequest.state === REQUEST_STATES.rejected &&
        showSentRequestDialog
      ) {
        setShowSentRequestDialog(false);
        enqueueSnackbar("Edit request rejected", { variant: "error" });
        fulfillRequest();
        return;
      }
    }

    // requestor - another user has overriden request
    if (
      currentUserId !== draftEdit.userId &&
      currentUserId !== draftSessionRequest.userId &&
      showSentRequestDialog === true
    ) {
      enqueueSnackbar("Another user has overriden your request to edit", {
        variant: "error",
      });
      setShowSentRequestDialog(false);
      return;
    }

    // current editor
    if (currentUserId === draftEdit.userId) {
      if (draftSessionRequest.state === REQUEST_STATES.requested) {
        setShowReceivedRequestDialog(true);
      }
      if (
        draftSessionRequest.state === REQUEST_STATES.accepted ||
        draftSessionRequest.state === REQUEST_STATES.rejected
      ) {
        setShowReceivedRequestDialog(false);
      }
      return;
    }
    setShowSentRequestDialog(false);
    setShowReceivedRequestDialog(false);
  }, [
    currentUserId,
    draftEdit,
    draftSessionRequest,
    enqueueSnackbar,
    showSentRequestDialog,
  ]);
  return (
    <>
      <RequestSentDialog
        open={showSentRequestDialog}
        onCancel={onCancelRequest}
        timeLeft={remainingRequestTime}
      />
      <RequestReceivedDialog
        open={showRecievedRequestDialog}
        draftSessionRequest={draftSessionRequest}
        onReject={onRejectRequest}
        onAccept={onAcceptRequest}
        timeLeft={remainingRequestTime}
      />
    </>
  );
};

export default DraftEditHandler;
