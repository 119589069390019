import { DRAFT_TYPES } from "../../config/drafts";
import { APP_BAR_CONFIG } from "./config";
import { ROUTES } from "../../routes/routes";

export const getDaftAppBarConfig = (draft) => {
  return draft && APP_BAR_CONFIG[draft.type];
};

export const openPreviewWindow = (draftId) => {
  const src = `${ROUTES.draft}/${draftId}${ROUTES.preview}`;
  window.open(
    src,
    "targetWindow",
    `toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=no,hr
    resizable=yes,
    width=390,
    height=844`
  );
  return false;
};

// TODO: error checks for each type may be better put inside config/draft.js, so this util function doesn't need to update as draft types get changed
export const checkForDraftErrors = (draft, data) => {
  const appBarConfig = getDaftAppBarConfig(draft);
  if (!appBarConfig) {
    return null;
  }

  if (draft.type === DRAFT_TYPES.myqr.key) {
    if (!data.button.text || !data.redirectUrl) {
      return {
        title: appBarConfig.publish.error.butonTextAndURL.title,
        desc: appBarConfig.publish.error.butonTextAndURL.desc,
      };
    }
  } else if (
    [DRAFT_TYPES.mystories.key, DRAFT_TYPES.mystories_v2.key].includes(
      draft.type
    )
  ) {
    const noSkipChapterURL = data.chapters.some((chapter) => {
      if (!chapter?.cta) return false;
      return (
        chapter.cta.value !== "None" &&
        chapter.cta.customInput &&
        chapter.cta.customInput === "chapters" &&
        !chapter.cta.url
      );
    });
    if (noSkipChapterURL) {
      return {
        title: appBarConfig.publish.error.noSkipChapter.title,
        desc: appBarConfig.publish.error.noSkipChapter.desc,
      };
    }
    const noURL = data.chapters.some(
      (chapter) =>
        chapter.cta && chapter.cta.value !== "None" && !chapter.cta.url
    );
    if (noURL) {
      return {
        title: appBarConfig.publish.error.noURL.title,
        desc: appBarConfig.publish.error.noURL.desc,
      };
    }
    return null;
  }
  return null;
};
