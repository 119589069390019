import axios from "axios";

import { getXssToken } from "./AuthService";

const API_BASE = "/createApi";

const API_PATHS = {
  user: {
    updateUserDraftRole: "/user/updateUserDraftRole",
    removeUserFromDraft: "/user/removeUserFromDraft",
  },
  draft: {
    join: "/draft/join",
    create: "/draft/create",
    duplicate: "/draft/duplicate",
    leave: "/draft/leave",
    getUpgradeVersions: "/draft/getUpgradeVersions",
    upgrade: "/draft/upgrade",
    restore: "/draft/restore",
  },
  draftCode: {
    create: "/draftCode/create",
    delete: "/draftCode/delete",
    deleteCodeAndUsers: "/draftCode/deleteCodeAndUsers",
  },
};

export const callApi = async (path, data) => {
  const xssToken = await getXssToken();
  const response = await axios.post(`${API_BASE}${path}`, data, {
    headers: { Authorization: `Bearer ${xssToken}` },
  });
  if (response.status !== 200) {
    throw new Error(response.status.message);
  }
  return response.data.result;
};

export const updateUserDraftRole = async ({
  userId,
  draftId,
  permissionKey,
}) => {
  return callApi(API_PATHS.user.updateUserDraftRole, {
    userId,
    draftId,
    permissionKey,
  });
};

export const removeUserFromDraft = async ({ userId, draftId }) => {
  return callApi(API_PATHS.user.removeUserFromDraft, {
    userId,
    draftId,
  });
};

export const createDraftCode = ({ userId, draftId, name, defaultRole }) => {
  const path = API_PATHS.draftCode.create;
  const data = {
    draftId,
    name,
    defaultRole,
    userId,
  };
  console.debug("createDraftCode", path, data);
  return callApi(path, data);
};

export const deleteCodeAndUsers = ({ code, draftId }) => {
  const path = API_PATHS.draftCode.deleteCodeAndUsers;
  const data = {
    code,
    draftId,
  };
  console.debug("deleteCodeAndUsers", path, data);
  return callApi(path, data);
};
export const deleteCode = ({ code, draftId }) => {
  const path = API_PATHS.draftCode.delete;
  const data = {
    code,
    draftId,
  };
  console.debug("deleteCode", path, data);
  return callApi(path, data);
};

export const joinDraft = ({ joinCode }) => {
  const path = API_PATHS.draft.join;
  const data = {
    joinCode,
  };
  console.debug("joinDraft", path, data);
  return callApi(path, data);
};

export const createDraft = ({ draftType }) => {
  const path = API_PATHS.draft.create;
  const data = {
    draftType,
  };
  console.debug("createDraft", path, data);
  return callApi(path, data);
};

export const duplicateDraft = ({ draftId, draftType, upgrade }) => {
  const path = API_PATHS.draft.duplicate;
  const data = {
    draftId,
    draftType,
    upgrade,
  };
  console.debug("duplicateDraft", path, data);
  return callApi(path, data);
};
export const leaveDraft = ({ draftId }) => {
  const path = API_PATHS.draft.leave;
  const data = {
    draftId,
  };
  console.debug("leaveDraft", path, data);
  return callApi(path, data);
};

export const getUpgradeVersions = ({ draftId }) => {
  const path = API_PATHS.draft.getUpgradeVersions;
  const data = { draftId };
  console.debug("getUpgradeVersions", path, data);
  return callApi(path, data);
};

export const upgradeDraft = ({ draftId, upgradeToVersion, createBackup }) => {
  const path = API_PATHS.draft.upgrade;
  const data = { draftId, upgradeToVersion, createBackup };
  console.debug("upgradeDraft", path, data);
  return callApi(path, data);
};

export const restoreDraft = ({ draftId }) => {
  const path = API_PATHS.draft.restore;
  const data = { draftId };
  console.debug("restoreDraft", path, data);
  return callApi(path, data);
};
