import { useFirestoreConnect } from "react-redux-firebase";
import { COLLECTIONS } from "../config/firebase";
import { useSelector } from "react-redux";

export const PERMISSIONS = {
  owner: {
    key: "owner",
    text: "Owner",
  },
  editor: {
    key: "editor",
    text: "Editor",
    order: 2,
  },
  viewer: {
    key: "viewer",
    text: "Viewer",
    order: 1,
  },
};
export const usePermissions = () => {
  useFirestoreConnect([
    {
      collection: COLLECTIONS.DRAFT_PERMISSIONS,
    },
  ]);

  const default_draft_permissions = useSelector(
    (state) => state.firestore.data.default_draft_permissions
  );
  return default_draft_permissions;
};
