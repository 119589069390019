import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { useDraftIdContext } from "../../../context";
import { ManageVersionDialog } from ".";
import { Auth } from "../../../App";
import { useUserPermissions } from "../../../services/UserService";

import STRINGS from "../../../strings/general";

const ManageVersionMenuItem = ({ onClose }) => {
  const userId = Auth.currentUser.uid;
  const draftId = useDraftIdContext();
  const permissions = useUserPermissions({ userId, draftId });
  const [openDialog, setOpenDialog] = useState(false);
  const enabled = permissions?.editor.write === true;

  const onClick = () => {
    setOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
    if (onClose) onClose();
  };

  return (
    <>
      <MenuItem onClick={onClick} disabled={!enabled}>
        {STRINGS.draft.manageTitle}
      </MenuItem>
      <ManageVersionDialog open={openDialog} onClose={onCloseDialog} />
    </>
  );
};

export default ManageVersionMenuItem;
