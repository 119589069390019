import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useDraftIdContext, useConfirmDialogContext } from "../../../context";
import { Auth } from "../../../App";
import { leaveDraft } from "../../../services/ApiService";
import { useDraft } from "../../../services/FirestoreService";
import { ROUTES } from "../../../routes/routes";

const MenuItemText = styled.span`
  margin-right: 0.5rem;
`;

const LeaveDraftMenuItem = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState();
  const history = useHistory();
  const currentUserId = Auth.currentUser.uid;
  const draftId = useDraftIdContext();
  const confirm = useConfirmDialogContext();
  const draft = useDraft({ draftId });
  const isDisabled = draft && draft.createdBy === currentUserId;

  const handleLeaveDraft = async () => {
    if (loading) return;
    try {
      await confirm({
        title: "Leave Draft",
        message: "Are you sure you want to stop participating in this draft?",
      });
    } catch (e) {
      return;
    }
    try {
      setLoading(true);
      await leaveDraft({
        draftId,
      });
      history.push(ROUTES.home);
      enqueueSnackbar(`You have left the draft "${draft.name}"`, {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <MenuItem onClick={handleLeaveDraft} disabled={isDisabled}>
      <MenuItemText>Leave Draft</MenuItemText>
    </MenuItem>
  );
};

export default LeaveDraftMenuItem;
