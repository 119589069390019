import React, { useState } from "react";
import styled from "styled-components";
import {
  Dialog,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  FormGroup,
  FormControl,
  TextField,
  CardActions,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { QueuePlayNextOutlined, Close, Check } from "@material-ui/icons";

const MyCard = styled(Card)`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const CardTitle = styled.span`
  font-weight: 600;
`;

const MyCardActions = styled(CardActions)`
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

const JoinDraftDialog = ({ open, handleClose, onConfirm }) => {
  const [joinCode, setJoinCode] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onClose = () => {
    handleClose();
    // setJoinCode("");
  };
  const title = <CardTitle>Join an existing draft</CardTitle>;

  const icon = <QueuePlayNextOutlined />;

  const onClickConfirm = () => {
    onConfirm(joinCode);
    // setJoinCode("");
  };

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth
    >
      <MyCard>
        <CardHeader title={title} avatar={icon} />
        <CardContent>
          <FormGroup>
            <FormControl fullWidth>
              <TextField
                variant="filled"
                label="Draft code"
                value={joinCode}
                fullWidth
                onChange={(e) => setJoinCode(e.target.value)}
              />
            </FormControl>
          </FormGroup>
        </CardContent>
        <MyCardActions disableSpacing={true}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <IconButton onClick={onClickConfirm} disabled={!joinCode}>
            <Check />
          </IconButton>
        </MyCardActions>
      </MyCard>
    </Dialog>
  );
};

export default JoinDraftDialog;
