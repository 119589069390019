import { useState, useEffect } from "react";
import { Firestore } from "../App";
import { COLLECTIONS } from "../config/firebase";
import {
  IFRAME_BASE_URL,
  REQUEST_STATES,
  REQUEST_TIMEOUT_SECONDS,
} from "../config/config";
import { DRAFT_TYPES } from "../config/drafts";

import { setDocument, getDocument, useDraft } from "./FirestoreService";
import { callApi } from "./ApiService";
import { resetIframeMessage } from "./MessageService";

export const updateLastModified = ({ draftId }) => {
  const data = {
    lastModifiedAt: Date.now(),
  };
  return updateDraftsDoc({ data, draftId });
};

export const updateLastPublished = ({ draftId }) => {
  const data = {
    lastPublishedAt: Date.now(),
  };
  return updateDraftsDoc({ data, draftId });
};

const updateDraftsDoc = ({ data, draftId }) => {
  const collection = COLLECTIONS.DRAFTS;
  const doc = draftId;
  return setDocument({ collection, doc, data });
};

export const useIframeUrl = ({ draftId, draftType, preview }) => {
  const [url, setUrl] = useState();
  const draft = useDraft({ draftId });
  const version = draft?.templateVersion;
  useEffect(() => {
    if (!draftId || !draftType) return;
    const base =
      process.env.NODE_ENV === "development"
        ? IFRAME_BASE_URL
        : `${IFRAME_BASE_URL}/${DRAFT_TYPES[draftType].storageFolder}/${draftId}`;
    setUrl(
      `${base}/app/index.html?draftId=${draftId}&t=${Date.now()}&preview=${preview}`
    );
    resetIframeMessage();
  }, [draftId, draftType, preview, version]);

  return url;
};

export const listProjects = async () => {
  console.debug("listProjects");
  return callApi("/listProjects", {});
};

export const publish = async ({ draftId, projectId, draftType }) => {
  console.debug("publish", draftId, projectId, draftType);
  await updateLastPublished({ draftId });
  return callApi("/publish", {
    draftId,
    projectId,
    draftType,
  });
};

export const isUserInDraft = async ({ userId, draftId }) => {
  const collection = COLLECTIONS.DRAFTS;
  const doc = draftId;
  const draft = await getDocument({ collection, doc });
  return draft.users.includes(userId);
};

export const updateDraftAndEditDoc = ({ draftId, data }) => {
  const collection = COLLECTIONS.DRAFTS_AND_EDIT;
  const doc = draftId;
  return setDocument({ collection, doc, data });
};

export const updateDraftAndRequestsDoc = ({ draftId, data }) => {
  const collection = COLLECTIONS.DRAFTS_AND_REQUESTS;
  const doc = draftId;
  return setDocument({ collection, doc, data });
};

export const handleSessionRequest = async (request) => {
  const collection = COLLECTIONS.DRAFTS_AND_REQUESTS;
  const doc = request.draftId;
  const data = request;
  data.createdAt = Date.now();
  await setDocument({ collection, doc, data });
};

// 1. update draftRequest to accepted
// 1. update editor user draft session state to viewer
// 1. update requestor user draft session state to editing
export const acceptDraftSessionRequest = async ({
  draftId,
  requestorUserId,
  requestorName,
}) => {
  const batch = Firestore.batch();
  const draftRequestRef = Firestore.collection(
    COLLECTIONS.DRAFTS_AND_REQUESTS
  ).doc(draftId);

  batch.set(
    draftRequestRef,
    { state: REQUEST_STATES.accepted },
    { merge: true }
  );

  const draftEditRef = Firestore.collection(COLLECTIONS.DRAFTS_AND_EDIT).doc(
    draftId
  );
  batch.set(
    draftEditRef,
    { userId: requestorUserId, name: requestorName },
    { merge: true }
  );

  return batch.commit();
};

export const useHandleDraftRequestTimer = ({
  draftSessionRequest,
  timerActive,
}) => {
  const [timeLeft, setTimeLeft] = useState();
  const [timerCreated, setTimerCreated] = useState(false);
  const createdAt = draftSessionRequest?.createdAt;
  const state = draftSessionRequest?.state;
  useEffect(() => {
    if (!timerActive) return;
    if (!createdAt) return;

    if (timerCreated) return;
    const interval = setInterval(() => {
      setTimerCreated(true);
      const timeSinceCreatedMs = Date.now() - createdAt;
      const timeSinceCreatedSeconds = Math.floor(timeSinceCreatedMs / 1000);
      const timeLeft = REQUEST_TIMEOUT_SECONDS - timeSinceCreatedSeconds;
      setTimeLeft(timeLeft < 0 ? 0 : timeLeft);
    }, 1000);

    if (state !== REQUEST_STATES.requested) {
      clearInterval(interval);
      setTimerCreated(false);
      setTimeLeft(REQUEST_TIMEOUT_SECONDS);
      return;
    }
    return () => {
      clearInterval(interval);
      setTimerCreated(false);
    };
  }, [createdAt, timerCreated, state, timerActive]);

  return timeLeft;
};

export const useHandleDraftRequestTimeout = ({
  remainingRequestTime,
  draftSessionRequest,
  onAcceptRequest,
  timerActive,
}) => {
  useEffect(() => {
    if (!timerActive) return;
    if (
      remainingRequestTime === 0 &&
      draftSessionRequest.state === REQUEST_STATES.requested
    ) {
      onAcceptRequest();
    }
  }, [remainingRequestTime, draftSessionRequest, onAcceptRequest, timerActive]);
};

export const checkIsDraftCollaborative = (draft) => {
  if (!draft) return;
  const templateVersion = draft.templateVersion;
  return !!templateVersion;
};

const splitDraftVersion = (version) => {
  const vers = version?.split("_");
  return vers && {
    type: vers.slice(0, -1).join("_"),
    version: vers.slice(-1)[0],
  };
}

export const getDraftTemplateVersion = (draft) =>
  splitDraftVersion(draft?.templateVersion);

export const getDraftOriginalVersion = (draft) =>
  splitDraftVersion(draft?.originalVersion);

// input = { type: "", version: "" }
// required = { typeKey: "minVersion" }
export const isVersionSupported = (input, required) => {
  if (!input || !required || !input.type || !input.version) throw Error("Invalid input");
  // check type
  if (!(input.type in required)) return false;
  const inputVers = input.version.split(".");
  const requiredVers = required[input.type].split(".");
  if (inputVers.length !== 2) throw Error(`Invalid input version '${input.version}'`);
  if (requiredVers.length !== 2) throw Error(`Invalid required version '${required[input.type]}'`);

  for (var i = 0; i < 2; i++) {
    if (parseInt(inputVers[i]) < parseInt(requiredVers[i])) return false;
  }
  return true;
};
