import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import styled from "styled-components";

import {
  PermIdentity,
  AssignmentIndOutlined,
  VisibilityOutlined,
  PhotoLibraryOutlined,
} from "@material-ui/icons";

import {
  useUser,
  getNameInitials,
  useUserPermissions,
} from "../../../services/UserService";
import { useDraftPermissionsContext } from "../../../context";

const MyCard = styled(Card)`
  height: ${(props) => (props.$mobile ? "270px" : "300px")};
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$mobile ? "150px" : "190px")};
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const MyCardActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0.5rem;
  justify-content: flex-end;
`;

const MyCardMedia = styled(CardMedia)`
  flex: 1;
  width: 100%;
`;

const AvatarContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  height: 1px;
`;

const MyAvatar = styled(Avatar)`
  height: 100%;
  width: 100%;
  font-size: 80px;
  font-weight: 900;
`;

const Content = styled.div`
  width: 100%;
  padding: 1rem;
`;

const FirstName = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const Permission = styled.div`
  font-size: 12px;
  color: #747474;
`;

const TeamMemberCard = ({ userId, draftId, onClick, progress, mobile }) => {
  const user = useUser({ userId });
  const userPermission = useUserPermissions({ userId, draftId });
  const permissionName = userPermission && userPermission.name;
  const userNameLetters = user && getNameInitials({ name: user.name });

  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;

  const firstName = user?.name?.length > 0 && user.name.split(" ")[0];
  const prepareIcon = () => {
    switch (permissionName) {
      case "Owner":
        return <PermIdentity />;
      case "Editor":
        return <AssignmentIndOutlined />;
      case "Viewer":
        return <VisibilityOutlined />;
      default:
        return <PhotoLibraryOutlined />;
    }
  };

  const gotoProfile = () => {
    if (userId && !progress && canEdit && onClick) {
      onClick(userId);
    }
  };

  return (
    <MyCard $mobile={mobile}>
      {progress && <LinearProgress />}
      <MyCardActionArea onClick={gotoProfile} component="div">
        {user?.img ? (
          <MyCardMedia image={user.img} />
        ) : (
          <AvatarContainer>
            <MyAvatar variant="square">{userNameLetters}</MyAvatar>
          </AvatarContainer>
        )}
        <Content>
          {prepareIcon()}
          {firstName && <FirstName>{firstName}</FirstName>}
          {permissionName && <Permission>{permissionName}</Permission>}
        </Content>
      </MyCardActionArea>
    </MyCard>
  );
};

export default TeamMemberCard;
