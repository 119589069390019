import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  LinearProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { getDraftRoute } from "../../../routes/routes";

import DraftTypeCard from "./DraftTypeCard";
import { DRAFT_TYPES } from "../../../config/drafts";
import STRINGS from "../../../strings/home";
import { createDraft } from "../../../services/ApiService";

const MyDialogToolbar = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.grey.main};
    display: flex;
    justify-content: flex-end;
  `}
`;

const MyDialogTitle = styled(DialogTitle)`
  ${({ theme, $fullScreen }) => `
  background-color: ${theme.palette.grey.main};
  text-align: center;
  padding-top: ${$fullScreen && 0};
  `}
`;
const GridContainer = styled(Grid)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey.main};
    padding: 2rem;
    text-align: center;
  `}
`;

const GridItem = styled(Grid)`
  ${({ theme }) => `
    background-color: ${theme.palette.grey.main};
  `}
`;

const CreateDraftDialog = ({ open, handleClose }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onCreate = async ({ key }) => {
    try {
      setLoading(true);
      const resp = await createDraft({ draftType: key });
      setLoading(false);
      enqueueSnackbar(STRINGS.create_dialog.success, {
        variant: "success",
      });
      handleClose();
      // enter newly created draft page
      if (resp?.newDraftId) history.push(getDraftRoute(resp.newDraftId));
    } catch (e) {
      console.log(e);
      enqueueSnackbar(STRINGS.create_dialog.fail, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const onClose = () => {
    if (loading) return;
    handleClose();
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        scroll="body"
      >
        {loading && <LinearProgress />}
        {fullScreen && (
          <MyDialogToolbar>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </MyDialogToolbar>
        )}
        <MyDialogTitle $fullScreen={fullScreen}>
          {STRINGS.create_dialog.title}
        </MyDialogTitle>
        <GridContainer container>
          {Object.keys(DRAFT_TYPES).map((key) => (
            <GridItem sm={12} md={4} item>
              <DraftTypeCard
                key={key}
                draftType={DRAFT_TYPES[key]}
                loading={loading}
                createDraft={onCreate}
              />
            </GridItem>
          ))}
        </GridContainer>
      </Dialog>
    </>
  );
};

export default CreateDraftDialog;
