import { OWNED_BY_ITEMS } from "./config/config";
export const draftContainsFilter = ({ draft, filter }) => {
  // nothing to check
  if (!draft || !filter) return true;

  if (filter.length === 0) return true;

  const filterLower = filter.toLowerCase();
  const nameLower = draft.name && draft.name.toLowerCase();
  const clientNameLower = draft.clientName && draft.clientName.toLowerCase();
  return (
    (nameLower && nameLower.includes(filterLower)) ||
    (clientNameLower && clientNameLower.includes(filterLower))
  );
};

export const draftOwnedByFilter = ({ draft, userId, ownedBy }) => {
  if (ownedBy === OWNED_BY_ITEMS.anyone) return true;
  if (ownedBy === OWNED_BY_ITEMS.me) {
    if (draft.createdBy === userId) return true;
    return false;
  }
  if (ownedBy === OWNED_BY_ITEMS.notMe) {
    if (draft.createdBy === userId) return false;
    return true;
  }
};

export const isUndefined = (obj) => typeof obj === "undefined";

export const sortUserDraftsCallback =
  (sortBy, sortDesc, userDrafts) => (a, b) => {
    a = userDrafts[a];
    b = userDrafts[b];
    const x = sortDesc ? b[sortBy] : a[sortBy];
    const y = sortDesc ? a[sortBy] : b[sortBy];
    if (isUndefined(x)) return isUndefined(y) ? 0 : 1;
    else if (isUndefined(y)) return -1;
    if (typeof x === "string" && typeof x === "string")
      return x.localeCompare(y);
    else return x - y;
  };

// a util function to control the frequency of triggering a callback
export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

export const getCookieByKey = (key) => {
  let name = key + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const copyText = (text) => navigator.clipboard.writeText(text);

export const getDraftEditorUser = (draftSessions) => {
  if (!draftSessions) return;
  let user;
  Object.keys(draftSessions).forEach((key) => {
    if (draftSessions[key].state === "editing") {
      user = draftSessions[key];
    }
  });
  return user;
};
