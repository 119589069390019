import React, { useState } from "react";
import styled from "styled-components";
import { MoreVert, Link } from "@material-ui/icons";
import { IconButton, Button, Menu, MenuItem } from "@material-ui/core";

import theme from "../../../config/theme";
import { UserAvatar } from "../../../components";
import { PERMISSIONS } from "../../../services/PermissionService";
import { getProjectJoinUrl } from "../../../routes/routes";
import { useDraftCodeMembers } from "../../../services/FirestoreService";

const Container = styled.div`
  background: ${(props) =>
    props.loading ? theme.palette.primary.main : theme.palette.grey.main};
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
`;

const ProfilePictureContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const TeamAvatar = styled(UserAvatar)`
  margin-right: 0.5rem;
  margin-top: 0.5rem;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid #d0d0d0;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-right: 0;
  padding-top: 0;
  width: 100%;
  flex: 1;
`;

const MembersContainer = styled.div`
  border-top: 1px solid #d0d0d0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

const AvatarsContainer = styled.div`
  flex-direction: row;
  display: flex;
`;

const TopContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const BottomContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex: 1;
`;

const BoldText = styled.span`
  font-weight: 600;
  font-size: 14px;
`;

const Text = styled.span`
  font-size: 14px;
`;

const Code = styled(BoldText)`
  margin-left: 0.5rem;
`;

const MyIconButton = styled(IconButton)`
  margin-left: auto;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled(BoldText)`
  margin-bottom: 0.5rem;
`;

const MyButton = styled(Button)`
  text-transform: none;
  width: 9rem;
  margin-right: 1rem;
  font-size: 1rem;
`;

const Actions = styled.div`
  margin-left: auto;
`;

const ProfilePicture = ({ userId }) => {
  return (
    <ProfilePictureContainer>
      <UserAvatar userId={userId} size="4.5rem" />
    </ProfilePictureContainer>
  );
};

const CodeRow = ({
  draftCode,
  onCopy,
  onDelete,
  loading,
  onDeleteCodeAndUsers,
  canEdit,
  mobile,
}) => {
  const [anchorOverflow, setAnchorOverflow] = useState(null);
  const defaultRole = PERMISSIONS[draftCode.defaultRole].text;
  const draftCodeMembers = useDraftCodeMembers({ code: draftCode.code });

  const onCopyCode = () => {
    onCopy(draftCode.code, "Code copied");
    setAnchorOverflow(null);
  };

  const onCopyLink = () => {
    onCopy(getProjectJoinUrl(draftCode.code), "Link copied");
    setAnchorOverflow(null);
  };

  const onClickDelete = () => {
    onDelete(draftCode.code);
    setAnchorOverflow(null);
  };

  const onClickDeleteCodeAndUsers = () => {
    onDeleteCodeAndUsers(draftCode.code);
    setAnchorOverflow(null);
  };

  return (
    <Container loading={loading}>
      <ProfilePicture userId={draftCode.createdBy} />
      <RightContainer>
        <InfoContainer>
          <TopContainer>
            <Link />
            <Code>{draftCode.code}</Code>
            <MyIconButton
              edge={false}
              onClick={(e) => setAnchorOverflow(e.currentTarget)}
            >
              <MoreVert />
            </MyIconButton>
            <Menu
              id="menuOverflow"
              anchorEl={anchorOverflow}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={anchorOverflow !== null}
              onClose={() => setAnchorOverflow(null)}
            >
              {mobile && (
                <>
                  <MenuItem onClick={onCopyCode}>Copy code</MenuItem>
                  <MenuItem onClick={onCopyLink}>Copy link</MenuItem>
                </>
              )}
              <MenuItem onClick={onClickDelete} disabled={loading || !canEdit}>
                Delete invite code
              </MenuItem>
              <MenuItem
                onClick={onClickDeleteCodeAndUsers}
                disabled={loading || !canEdit}
              >
                Delete invite code and users
              </MenuItem>
            </Menu>
          </TopContainer>
          <BottomContainer>
            <Details>
              <Name>{draftCode.name}</Name>
              <Text>Default Role: {defaultRole}</Text>
            </Details>
            {!mobile && (
              <Actions>
                <MyButton
                  variant="contained"
                  onClick={onCopyCode}
                  disabled={false}
                >
                  Copy Code
                </MyButton>
                <MyButton
                  variant="contained"
                  onClick={onCopyLink}
                  disabled={false}
                >
                  Copy Link
                </MyButton>
              </Actions>
            )}
          </BottomContainer>
        </InfoContainer>
        {draftCodeMembers && (
          <MembersContainer>
            <Text>Team</Text>
            <AvatarsContainer>
              {Object.values(draftCodeMembers).map((member) => {
                return (
                  <TeamAvatar
                    key={member.userId}
                    userId={member.userId}
                    size="3rem"
                  />
                );
              })}
            </AvatarsContainer>
          </MembersContainer>
        )}
      </RightContainer>
    </Container>
  );
};

export default CodeRow;
