import React from "react";
import { Provider, useSelector } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { createStore, combineReducers } from "redux";
import { SnackbarProvider } from "notistack";
import { Box } from "@material-ui/core";
import styled, { ThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";

import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
  isLoaded,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";

import { firebaseConfig } from "./config/firebase";
import { ROUTES, URL_PARAMS } from "./routes/routes";
import themeMyPlayer from "./config/theme";

import Home from "./pages/Home";
import DraftPage from "./pages/Draft";
import PreviewDraftPage from "./pages/Preview";
import Login from "./pages/Login";
import PrivateRoute from "./routes/PrivateRoute";
import CustomPrivateRoute from "./routes/CustomPrivateRoute";
import { Spinner } from "./components";
import { ConfirmDialogProvider, AdminModeProvider } from "./context";
import { isUserInDraft } from "./services/DraftService";
import STRINGS from "./strings/general";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize Firebase
export const Firebase = firebase.initializeApp(firebaseConfig);
export const Firestore = firebase.firestore();
export const Storage = firebase.storage().ref();
export const Auth = firebase.auth();
export const FieldValue = firebase.firestore.FieldValue;

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// Create store with reducers and initial state
const initialState = {};
const store =
  process.env.NODE_ENV === "production"
    ? createStore(rootReducer, initialState)
    : createStore(
        rootReducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Spinner />;
  return children;
};

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <MuiThemeProvider theme={themeMyPlayer}>
          <ThemeProvider theme={themeMyPlayer}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              autoHideDuration={3000}
            >
              <ConfirmDialogProvider>
                <Router>
                  <AuthIsLoaded>
                    <StylesProvider injectFirst>
                      <AdminModeProvider>
                        <Container>
                          <Switch>
                            <Route path={ROUTES.login}>
                              <Login />
                            </Route>
                            <Route path={ROUTES.debug}>
                              <h1>Debug</h1>
                            </Route>
                            <Route
                              path={`${ROUTES.draft}${URL_PARAMS.draftId}${ROUTES.preview}`}
                            >
                              <PrivateRoute>
                                <CustomPrivateRoute
                                  customAuthCheck={isUserInDraft}
                                  customAuthFailPath={ROUTES.home}
                                  authFailMessage={STRINGS.access.error}
                                >
                                  <PreviewDraftPage />
                                </CustomPrivateRoute>
                              </PrivateRoute>
                            </Route>
                            <Route
                              path={`${ROUTES.draft}${URL_PARAMS.draftId}`}
                            >
                              <PrivateRoute>
                                <CustomPrivateRoute
                                  customAuthCheck={isUserInDraft}
                                  customAuthFailPath={ROUTES.home}
                                  authFailMessage={STRINGS.access.error}
                                >
                                  <DraftPage />
                                </CustomPrivateRoute>
                              </PrivateRoute>
                            </Route>
                            {[ROUTES.home, ROUTES.join].map((route) => (
                              <Route key={route} exact path={route}>
                                <PrivateRoute>
                                  <Home />
                                </PrivateRoute>
                              </Route>
                            ))}
                          </Switch>
                        </Container>
                      </AdminModeProvider>
                    </StylesProvider>
                  </AuthIsLoaded>
                </Router>
              </ConfirmDialogProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}
