import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { ViewCarouselOutlined } from "@material-ui/icons";
import { SideBarContent } from ".";
import { ROUTES } from "../routes/routes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const Title = styled(Typography)`
  margin-bottom: 1rem;
`;

const MobileContent = ({ title, desc }) => {
  const sideBarConfig = {
    tabs: [
      {
        icon: <ViewCarouselOutlined />,
        label: "Drafts",
        route: ROUTES.home,
      },
    ],
  };
  return (
    <>
      <SideBarContent config={sideBarConfig} />
      <Container>
        <Title variant="subtitle1" component="div">
          {title}
        </Title>
        <Typography variant="body1" component="div" color="textSecondary">
          {desc}
        </Typography>
      </Container>
    </>
  );
};

export default MobileContent;
