import React, { useState } from "react";
import { Card } from "@material-ui/core";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  useDraftIdContext,
  useDraftPermissionsContext,
} from "../../../context";
import { useDraftInviteCodes } from "../../../services/FirestoreService";
import { Spinner } from "../../../components";
import { CodeRow } from ".";
import { copyText } from "../../../utils";
import { deleteCodeAndUsers, deleteCode } from "../../../services/ApiService";

const MyCard = styled(Card)`
  height: 100%;
  flex: 2;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

const EmptyTitle = styled.span`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Empty = () => {
  return (
    <EmptyContainer>
      <EmptyTitle>Your invite codes will appear here.</EmptyTitle>
      <span>
        To create your first one, fill in the details on the right and select
        Generate.
      </span>
    </EmptyContainer>
  );
};

const InviteCodeListCard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCodes, setLoadingCodes] = useState({});
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const draftId = useDraftIdContext();
  const currentUserPermissions = useDraftPermissionsContext();
  const canEdit = currentUserPermissions?.userPermissions?.write;
  const draftCodes = useDraftInviteCodes({ draftId });
  const loading = draftCodes === undefined;
  const empty = draftCodes === null;

  const onCopy = async (text, message) => {
    try {
      await copyText(text);
      enqueueSnackbar(message, { variant: "info" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    }
  };

  const onDeleteCodeAndUsers = async (code) => {
    try {
      setLoadingCodes({ ...loadingCodes, [code]: true });
      await deleteCodeAndUsers({ code, draftId });
      enqueueSnackbar("Invite code and users deleted!", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    } finally {
      setLoadingCodes({ ...loadingCodes, [code]: false });
    }
  };

  const onDeleteCode = async (code) => {
    try {
      setLoadingCodes({ ...loadingCodes, [code]: true });
      await deleteCode({ code, draftId });
      enqueueSnackbar("Invite code deleted!", { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("An error has occurred!", { variant: "error" });
    } finally {
      setLoadingCodes({ ...loadingCodes, [code]: false });
    }
  };

  return (
    <MyCard>
      {loading ? (
        <Spinner />
      ) : empty ? (
        <Empty />
      ) : (
        Object.keys(draftCodes).map((key) => {
          const draftCode = draftCodes[key];
          if (!draftCode) return <></>;
          return (
            <CodeRow
              mobile={mobile}
              key={`code-${draftCode.code}`}
              draftCode={draftCode}
              onCopy={onCopy}
              onDelete={onDeleteCode}
              onDeleteCodeAndUsers={onDeleteCodeAndUsers}
              loading={loadingCodes[draftCode.code]}
              canEdit={canEdit}
            />
          );
        })
      )}
    </MyCard>
  );
};

export default InviteCodeListCard;
