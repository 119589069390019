import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "../components";
import { Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";

// A wrapper for <Route>
// Performs a customAuthCheck for logged in users
// Should be used inside <PrivateRoute /> as it assumes the user is logged in

// Example
// <PrivateRoute>
//   <CustomPrivateRoute
//      customAuthCheck={isUserInDraft}
//      customAuthFailPath={ROUTES.home}
//      authFailMessage={STRINGS.access.error}
//    >
//      <MyStories />
//  </CustomPrivateRoute>
// </PrivateRoute>

const CustomPrivateRoute = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useRef(false);
  const auth = useSelector((state) => state.firebase.auth);
  const userId = auth?.uid;
  const customAuthCheck = props?.customAuthCheck;
  const customAuthFailPath = props?.customAuthFailPath;
  const authFailMessage = props?.authFailMessage;
  const pathname = window.location.pathname;

  useEffect(() => {
    mounted.current = true;

    const showErrorMessage = () => {
      if (authFailMessage) {
        enqueueSnackbar(authFailMessage, { variant: "warning" });
      }
    };

    const getDraftId = () => {
      const draftId = pathname.split("/")[2]
      return draftId;
    };

    const handleCustomAuthCheck = async () => {
      try {
        const draftId = getDraftId();
        const pass = await customAuthCheck({
          userId,
          draftId,
        });
        if (!mounted.current) return;
        if (pass) {
          setIsAuthenticated(true);
        } else {
          showErrorMessage();
          setIsAuthenticated(false);
        }
      } catch {
        if (!mounted.current) return;
        showErrorMessage();
        setIsAuthenticated(false);
      }
    };

    handleCustomAuthCheck();

    return () => {
      mounted.current = false;
    };
  }, [pathname, enqueueSnackbar, authFailMessage, userId, customAuthCheck]);

  if (isAuthenticated === undefined) {
    return <Spinner />;
  }
  if (isAuthenticated === false) {
    return <Redirect to={customAuthFailPath} />;
  }
  return props.children;
};

export default CustomPrivateRoute;
