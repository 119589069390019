import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// disable logging for non-development builds
if (
  process.env.NODE_ENV !== "development" &&
  !process.env.REACT_APP_DEMO_MODE
) {
  console.log = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
